import axios from "axios";
import {
  getClientKey,
  getRefreshToken,
  getToken,
  setClientKey,
  setRefreshToken,
  setToken,
} from "../utils/app.utils";
import _ from "lodash";
import liff from "@line/liff";

export const apiUrl = process.env.REACT_APP_API;
export const key = process.env.REACT_APP_X_API_KEY;
export let clientKey = getClientKey() || process.env.REACT_APP_CLIENT_KEY;
const pathAuthen = "authen/graphql";
let token = getToken();
let tokens = "";
const instance = axios.create({
  baseURL: apiUrl || process.env.REACT_APP_API,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": key,
    "client-key": clientKey,
    Authorization: tokens ? `Bearer ${tokens}` : token ? `Bearer ${token}` : "",
  },
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log(error);
  }
);

instance.interceptors.response.use(
  async (response: any) => {
    // console.log("response", response);
    const graphqlErrors = response?.data?.errors;
    // console.log("graphqlErrors", graphqlErrors);
    if (graphqlErrors) {
      const code = graphqlErrors[0]?.code;
      if (code === "AUTH_TOKEN_EXPIRED") {
        return handleGetRefreshToken(response);
      }
      if (
        ["AUTH_REFRESH_TOKEN_EXPIRED", "AUTH_SESSION_EXPIRED"].includes(code)
      ) {
        return handleRefreshTokenExpiration();
      }
    }
    return response;
  },
  async (error) => {
    // console.log("instance error", error);
    const originalRequest = error.config;

    if (
      error?.response &&
      (error?.response?.status === 401 ||
        error?.response?.data?.error.includes("401") ||
        error?.response?.data?.error.includes("403")) &&
      !originalRequest?._retry
    ) {
      originalRequest._retry = true;

      /* Call your refresh token function here */
      const refreshedToken = await refreshToken();
      const dtRefreshToken = refreshedToken?.data?.data;
      const errRefreshToken = _.head(refreshedToken?.data?.errors);
      /* Update the access token and retry the original request */
      if (dtRefreshToken) {
        await setAccessToken(dtRefreshToken?.userGetRefreshToken);
        // console.log("Before:", originalRequest.headers);
        originalRequest.headers.Authorization = `Bearer ${dtRefreshToken.userGetRefreshToken.accessToken}`;
        // console.log("After:", originalRequest.headers);
        return instance(originalRequest);
      } else {
        switch (errRefreshToken) {
          case 401:
            await handleRefreshTokenExpiration();
            break;
          default:
            await handleRefreshTokenExpiration();
            break;
        }
        return Promise.reject("LOGOUT");
      }
    }
    return Promise.reject(error);
  }
);

const setAccessToken = (dt: any) => {
  setToken(dt?.accessToken);
  setClientKey(dt?.clientKey);
  setRefreshToken(dt?.refreshToken);
  tokens = dt?.accessToken;
};

const handleRefreshTokenExpiration = async () => {
  await axios.post(
    `${apiUrl}/${pathAuthen}`,
    {
      query: `
        mutation revokeUserCustomerSessionByKey{
         revokeUserCustomerSessionByKey
        }
      `,
    },
    {
      headers: {
        Authorization: token,
        "x-api-key": key,
        "client-key": clientKey,
      },
    }
  );
  localStorage.clear();
  liff.closeWindow();
  // console.log("liffClose");
};

const refreshToken = async () => {
  try {
    const response = await axios.post(
      `${apiUrl}/${pathAuthen}`,
      {
        query: `
          mutation UserGetRefreshToken($input: AuthenCustomerRefreshTokenInput!) {
            userGetRefreshToken(input: $input) {
              accessToken
              refreshToken
              clientKey
            }
          }
        `,
        variables: { input: { refreshToken: getRefreshToken() } },
      },
      {
        headers: {
          Authorization: token,
          "x-api-key": key,
          "client-key": clientKey,
        },
      }
    );

    return response;
  } catch (error) {
    return null;
  }
};

const handleGetRefreshToken = async (response: any) => {
  const originalRequest = response.config;
  if (!originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const refreshedToken = await refreshToken();
      const dtRefreshToken = refreshedToken?.data?.data;
      const errRefreshToken = _.head(refreshedToken?.data?.errors);

      if (dtRefreshToken) {
        await setAccessToken(dtRefreshToken.userGetRefreshToken);
        // originalRequest.headers.Authorization = `Bearer ${dtRefreshToken.userGetRefreshToken.accessToken}`;
        // console.log("Before:", originalRequest.headers);
        originalRequest.headers.Authorization = `Bearer ${dtRefreshToken.userGetRefreshToken.accessToken}`;
        // console.log("After:", originalRequest.headers);

        return axios.request({ ...originalRequest });
      } else if (errRefreshToken) {
        return handleRefreshTokenExpiration();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

export default class BaseAPI {
  static api = instance;
}
