export const getToken = () => localStorage.getItem("ACCESS_TOKEN");
export const setToken = (value: string) =>
  localStorage.setItem("ACCESS_TOKEN", value);
export const getClientKey = () => localStorage.getItem("CLIENT_KEY");
export const setClientKey = (value: string) =>
  localStorage.setItem("CLIENT_KEY", value);
export const getRefreshToken = () => localStorage.getItem("REFRESH_TOKEN");
export const setRefreshToken = (value: string) =>
  localStorage.setItem("REFRESH_TOKEN", value);

export const logout = (): void => {
  localStorage.removeItem("ACCESS_TOKEN");
  localStorage.removeItem("REFRESH_TOKEN");
  // localStorage.removeItem("CLIENT_KEY");
};
