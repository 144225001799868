export const mockdataInstallment = [
  {
    period: 0,
    payment_due_date: "29/06/2014",
    status: "CONFIRMED",
    contract_no: "01-A-7051066",
    amount: 8900,
  },
  {
    period: 1,
    payment_due_date: "29/07/2014",
    status: "CONFIRMED",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 2,
    payment_due_date: "29/08/2014",
    status: "CONFIRMED",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 3,
    payment_due_date: "29/09/2014",
    status: "CONFIRMED",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 4,
    payment_due_date: "29/10/2014",
    status: "CONFIRMED",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 5,
    payment_due_date: "29/11/2014",
    status: "OVERDUED",
    contract_no: "01-A-7051066",
    amount: 2360,
  },
  {
    period: 6,
    payment_due_date: "29/12/2014",
    status: "OVERDUED",
    contract_no: "01-A-7051066",
    amount: 2460,
  },
  {
    period: 7,
    payment_due_date: "29/01/2015",
    status: "SOON",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 8,
    payment_due_date: "29/02/2015",
    status: "SOON",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 9,
    payment_due_date: "29/03/2015",
    status: "SOON",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
  {
    period: 10,
    payment_due_date: "29/04/2015",
    status: "SOON",
    contract_no: "01-A-7051066",
    amount: 2060,
  },
];
