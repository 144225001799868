import { useEffect, useState } from "react";
import UploadSlip from "./upload-slip";
import PayHistory from "./pay-history";
import PaymentMenu from "./payment-menu";
import PaymentQr from "./payment-qr";
import liff from "@line/liff";
import InstallmentPayment from "../aomdown-detail/installment-payment/installment-payment";

export default function PaymentPage() {
  // const [stepper, setStepper] = useState<number>(0);
  const [screen, setScreen] = useState<string>("menu");
  const [amount, setAmount] = useState<number>(0);
  const [contractNo, setContractNo] = useState<string>("");
  useEffect(() => {
    liff
      .init({
        liffId: `${process.env.REACT_APP_LIFF_ID_UPLOAD_SLIP}`,
        withLoginOnExternalBrowser: true,
      })
      .then(async () => {
        if (liff.isLoggedIn()) {
          return true;
        } else {
          liff.login();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {screen == "menu" && (
        <div>
          <PaymentMenu
            key="PaymentMenu"
            // onClickNext={() => setStepper(1)}
            // onClickBack={() => {
            //     setStepper(0);
            // }}
            // onChangeScreen={(screen) => { setScreen(screen), console.log(screen) }}
            onChangeScreen={(screen) => setScreen(screen)}
            onSetAmount={(amount) => setAmount(amount)}
            setContractNo={(e: any) => {
              setContractNo(e);
            }}
            contractNo={contractNo}
          />
        </div>
      )}

      {screen === "history" && (
        <div>
          <PayHistory
            key="PayHistory"
            // onClickNext={() => setStepper(2)}
            // onClickBack={() => {
            //     setStepper(0);
            // }}
            onClickBack={() => {
              setScreen("menu");
            }}
          />
        </div>
      )}
      {screen === "installment" && (
        <div>
          <InstallmentPayment
            key="InstallmentPayment"
            // onClickNext={() => setStepper(2)}
            // onClickBack={() => {
            //     setStepper(0);
            // }}
            contractNo={contractNo}
            onClickBack={() => {
              setScreen("menu");
            }}
            // onClickBack={() => {
            //   setScreen("menu");
            // }}
          />
        </div>
      )}

      {screen === "qr" && (
        <div>
          <PaymentQr
            key="PaymentQr"
            amount={amount}
            onSetAmount={() => {
              // console.log("Amount from PaymentQr:", amount);
              setAmount(amount);
            }}
            onClickBack={() => {
              setScreen("menu");
            }}
          />
        </div>
      )}
      {screen === "bank" && (
        <div>
          <UploadSlip
            key="UploadSlip"
            amount={amount}
            onClickBack={() => {
              setScreen("menu");
            }}
            // onClickNext={() => setStepper(1)}
            // onClickBack={() => {
            //     setStepper(0);
            // }}
          />
        </div>
      )}
    </div>
  );
}
