import errNoImg from '../assets/images/image-not-load.png'
import shibaProgress from '../assets/images/shiba_progress.png'
import thaiQrPayment from '../assets/images/thai_QR_Logo.jpg'
import thaiQrIcon from '../assets/images/thai-qr-icon.png'
import bankIcon from '../assets/images/bank-icon.png'
import logoKbank from '../assets/images/logoKbank.png'
import backgroundBanner from '../assets/images/background_banner.png'
import logOut from '../assets/images/logout.png'
import imageSuccess from '../assets/images/imageSuccess.png'
export const imgs = {
    errNoImg: errNoImg,
    shibaProgress: shibaProgress,
    logoKbank: logoKbank,
    backgroundBanner: backgroundBanner,
    logOut: logOut,
    thaiQrPayment: thaiQrPayment,
    thaiQrIcon: thaiQrIcon,
    bankIcon: bankIcon,
    imageSuccess: imageSuccess
}


export const onImgError = ({ currentTarget }: any) => {
    currentTarget.onerror = null // prevents looping
    currentTarget.src = imgs.errNoImg
}