import liff from "@line/liff";
import { useState } from "react";
import OtpApi from "../../../api/otp/otp.api";
import Loading from "../../../components/loading/loading";
import { swalError, swalWarning } from "../../../components/notification/swal";
import OtpComponant from "../../../components/otp/otp";
import {
  setClientKey,
  setRefreshToken,
  setToken,
} from "../../../utils/app.utils";
import { encryptString } from "../../../utils/crypto-js";
interface otp {
  loadCheckStep?: () => void;
  onClickNext?: (e: any) => void;
  dataProduct?: any;
  telCurrentUser: string;
  accessTokenLine: string | null;
  setUserDetail: (e: any) => void;
}

export default function Otp(props: otp) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [confirmOtp, setConfirmOtp] = useState<boolean>(false);
  const [errorOtp, setErrorOtp] = useState<string>("");

  const backStep = async () => {
    setPhoneNumber("");
    setLoadingPage(false);
    setConfirmOtp(false);
  };

  const verifyOtp = async (numberOtp: string) => {
    try {
      let type_create = props.telCurrentUser === phoneNumber ? false : true;
      setErrorOtp("");
      setLoadingPage(true);
      const encryptPhoneNumber = encryptString(phoneNumber);
      const encryptOtpNumber = encryptString(numberOtp);
      const payload = {
        tel: encryptPhoneNumber,
        otp: encryptOtpNumber,
        product: {
          model: props?.dataProduct.model,
          storage: props?.dataProduct.storage,
          color: props?.dataProduct.color,
        },
        downpayment_info: {
          price: props?.dataProduct.price,
          amount: props?.dataProduct.amount,
          time_payment: props?.dataProduct.time_payment,
          round_payment: props?.dataProduct.round_payment,
          round_amount: props?.dataProduct.round_amount,
        },
        type_create: type_create,
        accessTokenLine: props.accessTokenLine,
        type_aomdown: props?.dataProduct.type_aomdown,
      };

      const res = await OtpApi.createUser(payload);
      if (res.status === 200) {
        if (res.data?.message === "LOGIN") {
          setToken(res.data.data.accessToken);
          setRefreshToken(res.data.data.refreshToken);
          setClientKey(res.data.data.clientKey);
          setTimeout(() => {
            if (props.onClickNext) {
              props.onClickNext({
                token: res.data.data.accessToken,
                refreshToken: res.data.data.refreshToken,
                clientKey: res.data.data.clientKey,
              });
            }
          }, 500);
          window.location.reload();
        } else if (res.data.message === "CURRENT") {
          setTimeout(() => {
            if (props.loadCheckStep) props.loadCheckStep();
          }, 500);
        } else if (res.data.message === "NOTPASS-DEPOSIT") {
          // swalError("ตรวจพบสัญญา รอพิจรณาที่ค้างอยู่ ไม่สามารถทำรายการได้ ขออภัยในความไม่สะดวกค่ะ");
          // liff.closeWindow();
          swalError(
            "ตรวจพบสัญญารอพิจรณาที่ค้างอยู่ ไม่สามารถทำรายการได้ ขออภัยในความไม่สะดวกค่ะ",
            liff.closeWindow()
          );
        } else if (res.data.message === "NOTPASS-PRODUCT") {
          // swalError("ตรวจพบ สัญญาที่ ข้อมูลยังไม่ครบ กรุณากรอกข้อมูลและทำตามขั้นตอน ให้ครบก่อนนะคะ");
          // liff.closeWindow();
          swalError(
            "ตรวจพบสัญญาที่ข้อมูลยังไม่ครบ กรุณากรอกข้อมูลและทำตามขั้นตอนให้ครบก่อนนะคะ",
            liff.closeWindow()
          );
        } else if (res.data.message === "NOTPASS-CONTRACT") {
          // swalError("ตรวจพบ สัญญาที่ ข้อมูลยังไม่ครบ กรุณากรอกข้อมูลและทำตามขั้นตอน ให้ครบก่อนนะคะ");
          // liff.closeWindow();
          swalError(
            "ตรวจพบสัญญาที่ยังมีการใช้งาน ไม่สามารถทำรายการได้ ขออภัยในความไม่สะดวกค่ะ",
            liff.closeWindow()
          );
        } else if (res?.data?.message === "OTP-NOT-FOUND") {
          setErrorOtp("OTP ไม่ถูกต้อง");
        } else if (res?.data?.message === "OTP-OVER-VERIFY") {
          swalWarning(
            "",
            "คุณใส่รหัส OTP ผิดเกิน 5 ครั้ง ระบบจะพาคุณกลับไปหน้ากรอกเบอร์โทรศัพท์",
            backStep
          );
        }
        setLoadingPage(false);
      }
    } catch (error: any) {
      swalError(String(error?.response?.data?.message), backStep);
    }
  };

  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage}></Loading>
      <OtpComponant
        register
        phoneNumber={(data: any) => setPhoneNumber(data)}
        confirmOtp={confirmOtp}
        errorOtp={errorOtp}
        verify={(data: any) => verifyOtp(data)}
        verifyConfirmOtp={(data: any) => setConfirmOtp(data)}
      />
    </div>
  );
}
