import BaseAPI from "../api";

const path = "/aomdown/app/otp";
// const path = "/app/otp";


interface userCreate {
  tel: string;
  otp: string;
  product: {
    model: string;
    storage: string;
    color: string;
  },
  downpayment_info: {
    amount: number;
    time_payment: number;
    round_payment: string;
    round_amount: string;
  },
  type_create: boolean;
  accessTokenLine: string | null;
  // type_aomdown:string;
}

interface requestOtp {
  tel: string;
  page: string;
}

export default class OtpApi extends BaseAPI {
  static createUser(payload: userCreate): Promise<any> {
    return this.api.post(`${path}/createUser`, payload).then((res) => res);
  }

  static otpRequest(payload: requestOtp): Promise<any> {
    return this.api.post(`${path}/request`, payload).then((res) => res);
  }
}
