export enum CAREER {
    GOVERMENT = "พนักงานราชการ",
    PERMANENT = "พนักงานเงินเดือน",
    TEMPORARY = "พนักงานชั่วคราว/พนักงานอิสระ",
    OWNER = "เจ้าของกิจการ"
}

export enum TYPE_AOMDOWN {
    INSTALLMENT_AND_USE = "INSTALLMENT_AND_USE",
    PAY_THEN_RECEIVE = "PAY_THEN_RECEIVE"
}

export const careerVal = (val: string) => {
    switch (val) {
        case 'พนักงานราชการ':
            return 'สังกัดหน่วยงาน'
        case 'พนักงานเงินเดือน':
            return 'ลักษณะงานประจำ'
        case 'พนักงานชั่วคราว/พนักงานอิสระ':
            return 'ลักษณะงานที่ทำ'
        case 'เจ้าของกิจการ':
            return 'ลักษณะธุรกิจ'
        default:
            break;
    }
}

export const careerOcc = (val: string) => {
    switch (val) {
        case 'พนักงานราชการ':
            return 'ชื่อหน่วยงาน'
        case 'พนักงานเงินเดือน':
            return 'ชื่อบริษัท'
        case 'พนักงานชั่วคราว/พนักงานอิสระ':
            return 'ชื่อร้านที่ทำงาน/บริษัทที่จ้าง'
        case 'เจ้าของกิจการ':
            return 'ชื่อกิจการ/ร้านค้า'
        default:
            break;
    }
}

export const careerLocation = (val: string) => {
    switch (val) {
        case 'พนักงานราชการ':
            return 'ที่อยู่หน่วยงาน'
        case 'พนักงานเงินเดือน':
            return 'ที่อยู่ที่ทำงาน'
        case 'พนักงานชั่วคราว/พนักงานอิสระ':
            return 'ที่อยู่ร้านที่ทำงาน/บริษัทที่จ้าง'
        case 'เจ้าของกิจการ':
            return 'ที่อยู่กิจการ/ร้านค้า'
        default:
            break;
    }
}

export const keyLocalStorage = {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    CLIENT_KEY: 'CLIENT_KEY',
    REFRESH_TOKEN: 'REFRESH_TOKEN'
}