import liff from "@line/liff";
import { useEffect, useState } from "react";
import AuthenApi from "../../api/authen/authen.api";
import ButtonCustom from "../../components/button/buttonCustom";
import { swalError } from "../../components/notification/swal";
import { colors } from "../../constants/colors";
import { imgs } from "../../constants/images";

import HeaderText from "../../components/header/headerText";

import { Typography, Box } from "@mui/material";
import PaymentApi from "../../api/payment/payment.api";
import Download from "@mui/icons-material/FileDownloadOutlined";
import Loading from "../../components/loading/loading";
import { encryptString, encryptURLString } from "../../utils/crypto-js";
import { getClientKey, getToken } from "../../utils/app.utils";

interface uploadSlip {
  onClickBack?: (e: any) => void;
  onClickNext?: () => void;
  onSetAmount?: (amount: number) => void; // Ensure this function is typed to accept an amount
  amount: number;
}
// const mockInput = {
//     "id_card": "4336134595535",
//     "amount": 1
// }
// const mockPayloadQr = {
//     "success": true,
//     "message": "get qecode success",
//     "data": {
//         "amount": 1,
//         "payload": "00020101021230800016A000000677010112011501055671837620102134336134595535032017333054822634595535530376454041.005802TH5906SHBPQR63048B31",
//         "data_url": "",
//         "ref2": "17333054822634595535"
//     }
// }
export default function PaymentQr(props: uploadSlip) {
  const [fullName, setFullName] = useState("");
  const [loadingQR, setLoadingQR] = useState<boolean>(false);
  const [idCard, setIdCard] = useState<string>("");
  const [qrImage, setQrImage] = useState<any>();
  const [amountQR, setAmountQR] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [ref2, setRef2] = useState<string | null>(null);
  const currentTime = new Date().toLocaleTimeString("th-TH", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const currentTimeFile =
    new Date()
      .toLocaleDateString("th-TH", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) // ให้วันที่ในรูปแบบ '10/12/2567'
      .replace(/\//g, "_") +
    "-" +
    new Date()
      .toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(":", "_"); // แทนที่ ":" ด้วย "_"

  useEffect(() => {
    // console.log("Initial amount in PaymentQr:", props.amount);
    setAmount(props.amount);
  }, [props.amount]);
  // ฟังก์ชันสำหรับโหลดข้อมูลผู้ใช้งาน
  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      const fullName = result?.data?.data?.getCurrent?.fullName;
      const code = result?.data?.data?.getCurrent?.code;

      setFullName(fullName);
      setIdCard(code); // Set the idCard
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  // ฟังก์ชันในการดึงข้อมูล QR Payment
  const getQrPayment = async () => {
    setLoadingQR(true);
    const input = { id_card: idCard, amount: amount };

    if (!idCard || amount <= 0) {
      console.warn("Missing idCard or amountQR");
      return; // ไม่ทำงานถ้าข้อมูลไม่ครบ
    }

    try {
      const qrData = await PaymentApi.getQrPayment(input);
      if (qrData.status === 200 && qrData.data?.success) {
        setAmountQR(qrData.data?.data?.amount);
        setQrImage(qrData.data?.data?.data_url);
        setRef2(qrData.data?.data?.ref2);
      }
    } catch (err) {
      console.log("Error generating QR code:", err);
      swalError("ไม่สามารถสร้าง QR code ได้ในขณะนี้ : " + err);
    } finally {
      setLoadingQR(false);
    }
  };

  useEffect(() => {
    if (amount && idCard) {
      getQrPayment();
    }
  }, [amount, idCard]);

  useEffect(() => {
    if (qrImage) {
      const fetchBlobData = async () => {
        const apiUrl = process.env.REACT_APP_URL;
        const jsondata = {
          ref2: ref2,
          amount: amountQR,
          current: currentTime,
          fullName: fullName,
          currentFile: currentTimeFile,
          image: qrImage,
        };
        const dataEncypt = encryptURLString(JSON.stringify(jsondata));
        liff.openWindow({
          url: apiUrl + `/download-qrcode?data=${dataEncypt}`,
          external: true,
        });
      };
      fetchBlobData().catch(console.error);
      closePage();
      //   liff.closeWindow();
    }
  }, [qrImage]);

  // การส่งค่า amountQR ไปยัง parent component
  useEffect(() => {
    if (props.onSetAmount) {
      props.onSetAmount(amountQR); // ส่งค่า amountQR ไปยัง parent component
    } else {
      console.warn("onSetAmount is not provided"); // แจ้งเตือนถ้าไม่มีฟังก์ชันนี้
    }
  }, [amountQR, props]);

  const closePage = async () => {
    liff.closeWindow();
  };
  return (
    <div>
      {/* <Loading show={loadingPage || loading}></Loading> */}
      <div className="container pt-3 px-3 py-2">
        <HeaderText label="ชำระเงินด้วย QR CODE" enableBorder={true} />
      </div>
      <div className="d-flex justify-content-center container pt-3 px-3 py-1">
        <Typography className="fw-bold" color={colors.gray}>
          รอชำระเงิน
        </Typography>
      </div>
      {/* <div className="d-flex justify-content-center container pt-3 px-3 py-1">
        <Typography className="fw-bold" color={colors.gray}>
          ชื่อบัญชี บจก. ชิบะ โฟน จำนวนเงิน {amountQR} บาท
        </Typography>
      </div> */}
      {/* <Box>
        <div
          id="qrCodePayment"
          className="pt-3 px-3 d-flex flex-column align-items-center"
        >
          <img
            style={{ height: "60px", width: "300px" }}
            src={imgs.thaiQrPayment}
            alt="QR Payment"
          />
          {qrImage ? (
            <img
              src={qrImage}
              alt="QR Code"
              style={{ width: "300px", height: "300px" }}
            />
          ) : (
            <div style={{ width: "300px", height: "300px" }}>
              <Loading show={loadingQR}></Loading>
            </div>
          )}
        </div>
      </Box> */}
      <Box>
        {/* ปุ่มดาวน์โหลด */}
        {/* <Box className="d-flex justify-content-center mt-2 mb-2">
          <ButtonCustom
            mode={"default"}
            btnStyle={{ width: "100%", height: 40, borderRadius: 8 }}
            endIcon={<Download />}
            // onClick={() => {
            //   liff.openWindow({
            //     url: window.location.href, // เปิด URL ของรูป
            //     external: true, // บังคับให้เปิดใน Safari/Chrome แทน WebView
            //   });
            // }}
            onClick={handleDownload}
            textButton={"ดาวน์โหลด QR"}
          />
        </Box> */}

        {/* <Box>
          <div className="pt-3 px-4 py-1 d-flex flex-column align-items-start">
            <Typography className="fw-bold" color={colors.gray}>
              เลขอ้างอิง Ref2 : {ref2}
            </Typography>
            <Typography className="fw-bold" color={colors.gray}>
              สร้างเมื่อ : {currentTime}
            </Typography>

            <Typography className="fw-bold" sx={{ color: colors.danger }}>
              หมายเหตุ : QRCode นี้สามารถใช้ได้แค่ครั้งเดียวเท่านั้น กรุณา
              Capture หน้าจอเพื่อใช้ในการชำระเงิน
            </Typography>
          </div>
        </Box> */}

        {/* <div
          className="pt-3 px-3 py-2 d-flex flex-column align-items-center"
          style={{ textAlign: "center" }}
        >
          <Typography className="fw-bold" color={colors.danger}>
            QRCodeใช้สําหรับการชําระเงินของ <br />
            {fullName || ""} เท่านั้น!
          </Typography>
        </div> */}

        <Box className="pt-4 px-5 py-1 d-flex flex-column align-items-start">
          <ButtonCustom
            active={true}
            mode={"default"}
            style={{ width: "100%" }}
            textButton={"ย้อนกลับ"}
            onClick={() => {
              if (props.onClickBack) {
                props.onClickBack("menu");
                // console.log(props.onClickBack);
              }
            }}
          />
        </Box>
      </Box>
    </div>
  );
}
