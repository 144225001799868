import { useEffect, useRef, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Loading from "../../../components/loading/loading";
import HeaderText from "../../../components/header/headerText";
import { Col, Row } from "react-bootstrap";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import _ from "lodash";
import Webcam from "react-webcam";
import { colors } from "../../../constants/colors";
import ButtonCustom from "../../../components/button/buttonCustom";
import StorageUploadApi from "../../../api/storage/storage.api";
import { swalError, swalSuccess } from "../../../components/notification/swal";
import ContractApi from "../../../api/contract/contract.api";
import exampleCapture from "../../../assets/images/examplewebcam.png";
interface VerifyIdentify {
  contractNo: string;
  onSubmit: () => void;
}

export default function VerifyIdentify(props: VerifyIdentify) {
  const webcamRef = useRef<any>(null);
  const [imageSrc, setImageSrc] = useState<any>(null); // State to store the captured image

  const [openWebCam, setOpenWebCam] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });

    const file = new File([blob], "SignContact.png", { type: mimeString });
    return file; // คืนค่าที่เป็นไฟล์แทน blob
  };

  const capture = () => {
    const image = webcamRef.current.getScreenshot(); // Capture and convert to base64
    setImageSrc(image); // Store captured image
  };

  const onSubmit = async () => {
    const formData = new FormData();
    const data = dataURItoBlob(imageSrc);
    formData.append("image", data);
    formData.append("bucket", "contracts/id-card");
    try {
      // เรียกใช้ API อัปโหลดไฟล์
      setLoadingPage(true);
      const responseFileUpload = await StorageUploadApi.uploadFile(formData);

      if (responseFileUpload.status === 200) {
        const urlImage = responseFileUpload?.data?.url[0]?.url;
        const payload = {
          url: urlImage,
          contractNo: props?.contractNo,
        };
        await ContractApi.uploadIdCardContract(payload);
        setLoadingPage(false);
        if (props.onSubmit) props.onSubmit();
      }else {
        swalError(String("เกิดข้อผิดพลาดไม่สามารถทำรายการได้"));
        setLoadingPage(false);
      }
      setLoadingPage(false);
    } catch (error) {
      swalError(String("เกิดข้อผิดพลาดไม่สามารถทำรายการได้"));
      setLoadingPage(false);
      console.error("Upload failed:", error);
    }
  };
  useEffect(() => {
    console.log(imageSrc);
  }, [imageSrc]);
  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage} />
      <div className="pt-3 fw-bold">
        <HeaderText label="ยืนยันตัวตน" enableBorder={true} />
      </div>

      <div className="d-flex pt-2">
        <p style={{ color: colors.red }}>*</p>ถ่ายรูปหน้าคู่บัตรประชาชน
      </div>
      {!openWebCam && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: 1,
              borderStyle: "dashed",
              borderColor: colors.yellow,
              padding: "20px 20px",
            }}
          >
            <img
              src={exampleCapture}
              alt="Captured"
              style={{
                width: "100%",
                height: "90%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className="d-flex pt-2"
            style={{
              justifyContent: "center",
            }}
          >
            <p style={{ color: colors.red }}>
              *กรุณาถ่ายรูปให้เห็นใบหน้าและบัตรประชาชนให้ชัดเจน
            </p>
          </div>
          <Row className="pt-3">
            <Col>
              <ButtonCustom
                mode={"default"}
                btnStyle={{
                  width: "100%",
                }}
                textButton={"กดเพื่อถ่ายรูป"}
                onClick={() => {
                  setOpenWebCam(true);
                }}
              />
            </Col>
          </Row>
        </>
      )}
      {openWebCam && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: 1,
              borderStyle: "dashed",
              borderColor: colors.yellow,
              padding: "20px 20px",
            }}
          >
            {imageSrc ? (
              <img
                src={imageSrc}
                alt="Captured"
                style={{
                  width: "100%",
                  height: "500px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{
                  width: "100%",
                  height: "500px",
                  objectFit: "cover",
                }}
              />
            )}
            <Row className="pt-3">
              <Col>
                <ButtonCustom
                  mode={"default"}
                  btnStyle={{
                    width: "100%",
                  }}
                  endIcon={imageSrc ? <RestartAltIcon /> : <CameraAltIcon />}
                  textButton={imageSrc ? "ถ่ายรูปใหม่" : "ถ่ายรูป"}
                  onClick={() => {
                    if (imageSrc) {
                      setImageSrc("");
                    } else capture();
                  }}
                />
              </Col>
            </Row>
          </div>
          <div
            className="d-flex pt-2"
            style={{
              justifyContent: "center",
            }}
          >
            <p style={{ color: colors.red }}>
              *กรุณาถ่ายรูปให้เห็นใบหน้าและบัตรประชาชนให้ชัดเจน
            </p>
          </div>
          <Row className="pt-3">
            {/* <Col>
              <ButtonCustom
                mode={"default"}
                btnStyle={{
                  width: "100%",
                }}
                textButton={"ย้อนกลับ"}
                onClick={() => {
                  setOpenWebCam(false);
                }}
              />
            </Col> */}

            <Col>
              <ButtonCustom
                mode={"default"}
                btnStyle={{
                  width: "100%",
                }}
                textButton={"ถัดไป"}
                disabled={!imageSrc}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
