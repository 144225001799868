import liff from "@line/liff";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import AuthenApi from "../../../api/authen/authen.api";
import StorageUploadApi from "../../../api/storage/storage.api";
import Sign from "../../../assets/icon/sign";
import ButtonCustom from "../../../components/button/buttonCustom";
import Loading from "../../../components/loading/loading";
import { swalError, swalSuccess } from "../../../components/notification/swal";
import { colors } from "../../../constants/colors";
import ContractApi from "../../../api/contract/contract.api";

interface props {
    contractNo: string
    accTokenLine: string
    onSubmit: () => void;
}
export default function SignContact(props: props) {
    const singpad = useRef<SignatureCanvas>(null);
    const [signatureDataURL, setSignatureDataURL] = useState<any>();
    const [loadingPage, setLoadingPage] = useState(false);

    const dataURItoBlob = (dataURI: string) => {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        const file = new File([blob], "SignContact.png", { type: mimeString });
        return file; // คืนค่าที่เป็นไฟล์แทน blob

    };

    const handleEnd = () => {
        if (singpad.current) {
            const dataUrl = singpad.current.toDataURL(); // Get base64 data URL
            const data = dataURItoBlob(dataUrl);

            setSignatureDataURL(data);
        }
    };

    const closePage = async () => {
        await AuthenApi.verify(props.accTokenLine || '')
        liff.closeWindow();
    }

    const uploadImg = async (signatureBlob: Blob) => {
      if (!signatureBlob) {
          setLoadingPage(false);
          return;
      }
  
      const formData = new FormData();
      formData.append('image', signatureBlob);
      formData.append('bucket', "contracts/signature");
  
      try {
          setLoadingPage(true);
          const responseFileUpload = await StorageUploadApi.uploadFile(formData);
  
          if (responseFileUpload.status !== 200) {
            console.log(responseFileUpload.status);
            swalError(String(' FileUpload เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
          }
  
          const urlImageSign = responseFileUpload?.data?.url[0]?.url;
          const payload = {
              url: urlImageSign,
              contractNo: props?.contractNo
          };
  
          const response = await ContractApi.uploadSignatureContract(payload);
         
  
          if (response.status !== 200) {
            console.log("Contract", response.status);
            swalError(String('Contract เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
          }
  
          if (props.onSubmit) props.onSubmit();
      } catch (error) {
          swalError(String(`เกิดข้อผิดพลาดไม่สามารถทำรายการได้ ${error}`));
          console.error('Upload failed:', error);
      } finally {
          setLoadingPage(false);
      }
  };

    // const uploadImg = async (signatureBlob: Blob) => {
    //     if (signatureBlob) {
    //         const formData = new FormData();
    //         formData.append('image', signatureBlob);
    //         formData.append('bucket', "contracts/signature");

    //         try {
    //             setLoadingPage(true);
    //             const responseFileUpload = await StorageUploadApi.uploadFile(formData);

    //             if (responseFileUpload.status === 200) {
    //                 const urlImageSign = responseFileUpload?.data?.url[0]?.url
    //                 const payload = {
    //                     url: urlImageSign,
    //                     contractNo: props?.contractNo
    //                 }
    //                 const response = await ContractApi.uploadSignatureContract(payload);
    //                 console.log("Upload response:", response);
                
    //                 if (response.status === 200) {
    //                     setLoadingPage(false);
    //                     if (props.onSubmit) props.onSubmit();
    //                 } else {
    //                   swalError(String("เกิดข้อผิดพลาดไม่สามารถทำรายการได้"));
    //                   setLoadingPage(false);
    //                 }
    //             }
    //             else {
    //               swalError(String("เกิดข้อผิดพลาดไม่สามารถทำรายการได้"));
    //               setLoadingPage(false);
    //             }
    //             setLoadingPage(false);
    //         } catch (error) {
    //             swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
    //             setLoadingPage(false);
    //             console.error('Upload failed:', error);
    //         }
    //         setLoadingPage(false);
    //     } else {
    //         setLoadingPage(false);
    //     }
    // }

    const handleClear = () => {
        if (singpad.current) {
            singpad.current.clear();
            setSignatureDataURL("");
        }
    };

    return (
        <div className="px-3 py-3">
            <Loading show={loadingPage} />
            <div >
                <p className="font-bold font-16" > ลงลายเซ็น <Sign></Sign></p>
                <p className="fw-300 font-14" style={{ color: colors.darkGray }}>
                    กรุณาลงลายมือชื่อ โดยใช้นิ้ววาดลงในพื้นที่ด้านล่าง
                </p>
            </div>

            <div className="pt-1"
                style={{
                    display: "flex",
                    width: "100%",
                    height: "385px",
                    backgroundColor: colors.bgModal,
                    borderRadius: 8
                }}>
                <SignatureCanvas
                    canvasProps={{ className: "sigCanvas" }}
                    ref={singpad}
                    onEnd={handleEnd}
                />
            </div>
            <div className="pt-3">
                <ButtonCustom
                    mode={'default'}
                    btnStyle={{ width: '100%' }}
                    textButton={'ยืนยัน'}
                    disabled={!signatureDataURL}
                    onClick={() => {
                        uploadImg(signatureDataURL);
                        handleClear()
                    }}
                />
            </div>
            <div className="pt-3">
                <ButtonCustom
                    btnStyle={{ width: '100%' }}
                    variant="outlined"
                    disabled={!signatureDataURL}
                    textButton={'ล้างลายเซ็น'}
                    onClick={() => {
                        handleClear()
                    }}
                />
            </div>
        </div>
    )
}