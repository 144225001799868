import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import HeaderText from "../components/header/headerText";
import { colors } from "../constants/colors";
import ButtonCustom from "../components/button/buttonCustom";
import { Download } from "@mui/icons-material";
import Loading from "../components/loading/loading";
import { imgs } from "../constants/images";
import { decryptURLString } from "../utils/crypto-js";
export default function DowloadQrcodePage() {
  const location = useLocation();
  // Function to get query parameters
  const getQueryParam = (param: any) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };
  const [loadingQR, setLoadingQR] = useState<boolean>(false);
  const [qrImage, setQrImage] = useState<any>();
  const [amountQR, setAmountQR] = useState<number>(0);
  const [ref2, setRef2] = useState<string | null>(null);
  const [currentTimeFile, setCurrentTimeFile] = useState<string | null>("");
  const [currentTime, setCurrentTime] = useState<string | null>("");
  const [fullName, setFullName] = useState<string | null>("");
  useEffect(() => {
    const code = getQueryParam("data") || "";
    // console.log("code", code);
    const jsondata = JSON.parse(decryptURLString(code));
    // console.log("jsondata", jsondata);
    setAmountQR(Number(jsondata?.amount));
    setQrImage(jsondata?.image);

    setCurrentTime(jsondata?.current);
    setCurrentTimeFile(jsondata?.currentFile);
    setFullName(jsondata?.fullName);
    setRef2(jsondata?.ref2);
    setLoadingQR(true);
  }, []);
  const handleDownload = async () => {
    try {
      const response = await fetch(qrImage);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `QRCode-${fullName}-${currentTimeFile}.png`; // กำหนดชื่อไฟล์
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download error:", error);
    }
  };
  return (
    <div>
      {/* <Loading show={loadingPage || loading}></Loading> */}
      <div className="container pt-3 px-3 py-2">
        <HeaderText label="ชำระเงินด้วย QR CODE" enableBorder={true} />
      </div>
      <div className="d-flex justify-content-center container pt-3 px-3 py-1">
        <Typography className="fw-bold" color={colors.gray}>
          ชื่อบัญชี บจก. ชิบะ โฟน จำนวนเงิน {amountQR} บาท
        </Typography>
      </div>
      <Box>
        <div
          id="qrCodePayment"
          className="pt-3 px-3 d-flex flex-column align-items-center"
        >
          <img
            style={{ height: "60px", width: "300px" }}
            src={imgs.thaiQrPayment}
            alt="QR Payment"
          />
          {qrImage ? (
            <img
              src={qrImage}
              alt="QR Code"
              style={{ width: "300px", height: "300px" }}
            />
          ) : (
            <div style={{ width: "300px", height: "300px" }}>
              <Loading show={loadingQR}></Loading>
            </div>
          )}
        </div>
      </Box>
      <Box>
        {/* ปุ่มดาวน์โหลด */}
        <Box className="d-flex justify-content-center mt-2 mb-2">
          <ButtonCustom
            mode={"default"}
            btnStyle={{ width: "100%", height: 40, borderRadius: 8 }}
            endIcon={<Download />}
            onClick={handleDownload}
            textButton={"ดาวน์โหลด QR"}
          />
        </Box>

        <Box>
          <div className="pt-3 px-4 py-1 d-flex flex-column align-items-start">
            <Typography className="fw-bold" color={colors.gray}>
              เลขอ้างอิง Ref2 : {ref2}
            </Typography>
            <Typography className="fw-bold" color={colors.gray}>
              สร้างเมื่อ : {currentTime}
            </Typography>

            <Typography className="fw-bold" sx={{ color: colors.danger }}>
              หมายเหตุ : QRCode นี้สามารถใช้ได้แค่ครั้งเดียวเท่านั้น
            </Typography>
          </div>
        </Box>

        <div
          className="pt-3 px-3 py-2 d-flex flex-column align-items-center"
          style={{ textAlign: "center" }}
        >
          <Typography className="fw-bold" color={colors.danger}>
            QRCodeใช้สําหรับการชําระเงินของ {fullName || ""} เท่านั้น!
          </Typography>
        </div>
      </Box>
    </div>
  );
}
