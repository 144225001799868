import BaseAPI from "../api-upload";

const path = "/payment/app";

export default class PaymentApi extends BaseAPI {
  static getReceiptHistory(payload: any): Promise<any> {
    return this.api
      .get(`${path}/receipts/receipt-all`, { params: payload })
      .then((res) => res);
    // console.log(payload);
    //return this.api.get(`http://localhost:3001/app/receipts/receipt-all`, { params: payload }).then((res) => res)
  }

  static getReceiptPDF(payload: any): Promise<any> {
    return this.api
      .post(`${path}/receipts/receipt-no`, payload)
      .then((res) => res);
    //return this.api.post(`http://localhost:3001/app/receipts/receipt-no`, payload).then((res) => res)
  }

  static getQrPayment(payload: any): Promise<any> {
    return this.api.post(`${path}/bbl/get-qrcode`, payload).then((res) => res);
    // console.log(payload);
    // return this.api.post(`http://localhost:3001/bbl/get-qrcode`, payload).then((res) => res)
    //return this.api.post(`http://localhost:3001/app/receipts/receipt-no`, payload).then((res) => res)
  }

  static getQrPaymentextenal(payload: any, token: string): Promise<any> {
    const access = token.split("|");
    const key = process.env.REACT_APP_X_API_KEY;
    return this.api
      .post(`${path}/bbl/get-qrcode`, payload, {
        headers: {
          "x-api-key": key,
          "client-key": access[1],
          Authorization: access[0] ? `Bearer ${access[0]}` : "",
        },
      })
      .then((res) => res);
    // console.log(payload);
    // return this.api.post(`http://localhost:3001/bbl/get-qrcode`, payload).then((res) => res)
    //return this.api.post(`http://localhost:3001/app/receipts/receipt-no`, payload).then((res) => res)
  }
}
