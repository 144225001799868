import liff from "@line/liff";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import OtpComponant from "../../../components/otp/otp";
import { swalError, swalWarning } from "../../../components/notification/swal";
import { encryptString } from "../../../utils/crypto-js";
import AuthenApi from "../../../api/authen/authen.api";
interface otp {
  onSubmit: () => void;
  phoneNumber: string | "";
  accessTokenLine: string | null;
}

export default function Otp(props: otp) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [confirmOtp, setConfirmOtp] = useState<boolean>(false);
  const [errorOtp, setErrorOtp] = useState<string>("");
  const [reOtp, setReOtp] = useState<boolean>(false);
  const verifyOtp = async (numberOtp: string) => {
    try {
      setErrorOtp("");
      setLoadingPage(true);
      const encryptPhoneNumber = encryptString(phoneNumber);
      const encryptOtp = encryptString(numberOtp);
      const payload = {
        phoneNumber: encryptPhoneNumber,
        otp: encryptOtp,
        accessTokenLine: props.accessTokenLine || "",
      };
      const res = await AuthenApi.login(payload);
      if (res.status === 200) {
        if (res.data.data.userLogin.message === "OTP_OVER_VERIFY") {
          swalWarning(``, "คุณใส่รหัส OTP ผิดเกิน 5 ครั้ง");
          setReOtp(true);
          setLoadingPage(false);
          return false;
        }
        if (res.data.data.userLogin.message === "REGISTER") {
          swalError(`ไม่พบข้อมูล กรุณาลงทะเบียนก่อน เข้าสู่ระบบ`);
          return false;
        }
        if (res.data.data.userLogin.message === "OTP_NOT_FOUND") {
          setErrorOtp("OTP ไม่ถูกต้อง");
          setLoadingPage(false);
          return false;
        }
        setLoadingPage(false);
        if (res.data.data.userLogin.accessToken) {
          if (props?.onSubmit) props?.onSubmit();
        }
      }
      setLoadingPage(false);
    } catch (error: any) {
      setLoadingPage(false);
      swalError(error.response.data.message);
    }
  };

  useEffect(() => {
    setPhoneNumber(props.phoneNumber);
  }, []);
  useEffect(() => {
    if (reOtp) setReOtp(false);
  }, [reOtp]);
  // console.log("props.phoneNumber", props.phoneNumber);
  // console.log("phoneNumber", phoneNumber);
  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage}></Loading>
      <OtpComponant
        defaultPhoneNumber={props.phoneNumber || ""}
        phoneNumber={(data: any) => setPhoneNumber(data)}
        confirmOtp={confirmOtp}
        errorOtp={errorOtp}
        verify={(data: any) => verifyOtp(data)}
        verifyConfirmOtp={(data: any) => setConfirmOtp(data)}
        reOtp={reOtp}
      />
    </div>
  );
}
