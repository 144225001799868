import CryptoJS from "crypto-js";

const KEY = process.env.REACT_APP_ENCRYPT_KEY as string;

export const encryptString = (str: string) => {
  return CryptoJS.AES.encrypt(str, KEY).toString();
};

export const decryptString = (encodeStr: string) => {
  return CryptoJS.AES.decrypt(encodeStr, KEY).toString(CryptoJS.enc.Utf8);
};

export const encryptURLString = (str: string) => {
  return encodeURIComponent(CryptoJS.AES.encrypt(str, KEY).toString());
};

export const decryptURLString = (encodeStr: string) => {
  return CryptoJS.AES.decrypt(decodeURIComponent(encodeStr), KEY).toString(
    CryptoJS.enc.Utf8
  );
};
