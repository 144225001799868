export const optionsPrefix = [
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
  { value: "อื่นๆ", label: "อื่นๆ" },
];

export const optionRelationshipsParent = [
  { value: "พ่อแม่", label: "พ่อแม่" },
  { value: "พี่น้อง", label: "พี่น้อง" },
  { value: "ญาติ", label: "ญาติ" },
];

export const optionRelationships = [
  { value: "พ่อแม่", label: "พ่อแม่" },
  { value: "พี่น้อง", label: "พี่น้อง" },
  { value: "สามี/ภรรยา/แฟน", label: "สามี/ภรรยา/แฟน" },
  { value: "ลูก", label: "ลูก" },
  { value: "ญาติ", label: "ญาติ" },
  { value: "เพื่อน/หัวหน้างาน", label: "เพื่อน/หัวหน้างาน" },
];
