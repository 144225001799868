import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import HeaderText from "../../../components/header/headerText";
import ButtonCustom from "../../../components/button/buttonCustom";
import { colors } from "../../../constants/colors";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { mockdataInstallment } from "./mock";
import { numberFormat } from "../../../utils/common";
import _ from "lodash";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function renderList(datalist: any) {
  return datalist.map((item: any, index: number) => {
    return (
      <div className="pay-history-card" key={index}>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <div className="d-flex">
              <Typography
                variant="body1"
                className=" fw-bold"
                color={colors.purple}
              >
                {item?.period === 0 ? "เงินดาวน์" : `งวดที่: ${item?.period} `}
              </Typography>
              {item?.status === "OVERDUED" ? (
                <Typography
                  variant="body1"
                  className=" fw-bold"
                  color={colors.danger}
                  style={{ marginLeft: "8px" }}
                >
                  ค้างชำระ
                </Typography>
              ) : (
                <></>
              )}
            </div>
            <Typography variant="body2" className="pt-2 pb-1">
              {`กำหนดชำระ: ${item?.payment_due_date}`}
            </Typography>
            <Typography variant="body2" className="pt-2 pb-1">
              {`เลขที่สัญญา: ${item?.contract_no}`}
            </Typography>
          </div>
          <div>
            <Typography
              variant="h5"
              className="py-1 fw-bold"
              color={colors.pink}
            >
              {`${numberFormat(item?.amount, 0, 0)}`}
            </Typography>
            <Typography
              variant="body2"
              className="py-1 fw-bold"
              color={colors.pink}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {`บาท`}
            </Typography>
          </div>
        </div>
      </div>
    );
  });
}

interface payHistory {
  contractNo: string;
  onClickBack?: () => void;
}

const InstallmentPayment = (props: payHistory) => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [dataInstallment, setDataInstallment] = useState<any>([]);
  const [dataPayment, setDataPayment] = useState<any>([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    // console.log("contractNo", props.contractNo);
    if (!_.isEmpty(props.contractNo)) {
      const paymentFilter = mockdataInstallment.filter(
        (data) => data.status === "CONFIRMED"
      );
      const installmentFilter = mockdataInstallment.filter(
        (data) => data.status !== "CONFIRMED"
      );
      setDataPayment(paymentFilter);
      setDataInstallment(installmentFilter);
    }
  }, [props.contractNo]);
  //   console.log("dataInstallment", dataInstallment);
  //   console.log("dataPayment", dataPayment);

  return (
    <div className="container pt-3 px-3 py-2">
      <Loading show={loadingPage} />
      <div className="pb-3">
        <div
          className="d-flex justify-content-between py-3 pe-4 position-fixed top-0"
          style={{ backgroundColor: colors.white, width: "100%", zIndex: 100 }}
        >
          <HeaderText label="ค่างวดชำระ" enableBorder={true} />
          <ButtonCustom
            mode={"default"}
            variant={"outlined"}
            textButton={"ย้อนกลับ"}
            active={true}
            onClick={() => {
              if (props.onClickBack) {
                props.onClickBack();
              }
            }}
          />
        </div>
        <div className="pt-5 mt-2 pb-5">
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            TabIndicatorProps={{ sx: { bgcolor: colors.themeMainColor } }}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="ค่างวดชำระ" {...a11yProps(0)} />
            <Tab label="ชำระแล้ว" {...a11yProps(1)} />
          </Tabs>
          <div>
            <TabPanel value={valueTab} index={0}>
              {renderList(dataInstallment)}
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              {renderList(dataPayment)}
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallmentPayment;
