import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import HeaderText from "../../../components/header/headerText";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../components/input/inputTextField";
import InputDatePicker from "../../../components/input/inputDatePicker";
import { TYPE_AOMDOWN } from "../../../utils/enum";
import { calculateAge } from "../../../utils/date.utils";
import moment from "moment";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import _ from "lodash";
import { colors } from "../../../constants/colors";
import ButtonCustom from "../../../components/button/buttonCustom";
import MasterDataApi from "../../../api/masterdata/masterdata.api";
import AuthenApi from "../../../api/authen/authen.api";
import PersonalDetailApi from "../../../api/personal-detail/personalDetail.api";
import { swalError } from "../../../components/notification/swal";
import {
  optionRelationships,
  optionRelationshipsParent,
} from "../../../utils/options";
import {
  checkPattenPhoneNumber,
  chkDigitPid,
  idCardFormat,
} from "../../../utils/common";
import ModalCustom from "../../../components/modalCustom/modalCustom";
import { decryptString } from "../../../utils/crypto-js";

interface AdditionalInformation {
  typeAomdown: string;
  downPaymentNo?: string;
  onSubmit: () => void;
}

const initStateErrorMessage = {
  NAME: ``,
  LASTNAME: ``,
  BIRTHDAY: "",
  ADDRESS: "",
  ZIPCODE: "",
  PROVINCE: "",
  DISTRICT: "",
  SUB_DISTRICT: "",
  IDCARD_PARENT: "",
  PARENT_NAME: "",
  PARENT_LASTNAME: "",
  PARENT_PHONE: "",
  PARENT_RELATIONSHIP: "",
  CONTACT_NAME: "",
  CONTACT_LASTNAME: "",
  CONTACT_PHONE: "",
  CONTACT_RELATIONSHIP: "",
};

type ErrorMessageType = typeof initStateErrorMessage;

export default function AdditionalInformation(props: AdditionalInformation) {
  const today = new Date();
  let minDay = props.typeAomdown === TYPE_AOMDOWN.INSTALLMENT_AND_USE ? 15 : 20;
  const maxDate = new Date(
    today.getFullYear() - minDay,
    today.getMonth(),
    today.getDate()
  );
  const minDate = new Date(
    today.getFullYear() - 60,
    today.getMonth(),
    today.getDate()
  );
  const [loadingPage, setLoadingPage] = useState(false);
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [birthday, setBirthDay] = useState<string>("");
  const [tel, setTel] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [age, setAge] = useState<number>(0);
  const [address, setAddress] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [subdistrict, setSubdistrict] = useState<string>("");
  const [idcardParent, setIdcardParent] = useState<string>("");
  const [parentName, setParentName] = useState<string>("");
  const [parentLastname, setParentLastname] = useState<string>("");
  const [parentPhoneNumber, setParentPhoneNumber] = useState<string>("");
  const [parentRelationship, setParentRelationship] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [contactLastname, setContactLastname] = useState<string>("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string>("");
  const [contactRelatiionship, setContactRelationship] = useState<string>("");

  const [optionAddress, setOptionAddress] = useState<any[]>([]);
  const [optionProvice, setOptionProvice] = useState<any[]>([]);
  const [optionProviceMaster, setOptionProviceMaster] = useState<any[]>([]);
  const [optionDistrict, setOptionDistrict] = useState<any[]>([]);
  const [optionDistrictMaster, setOptionDistrictMaster] = useState<any[]>([]);
  const [optionSubDistrict, setOptionSubdistrict] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  useEffect(() => {
    const age = calculateAge(birthday);
    setAge(age || 0);
  }, [birthday]);

  useEffect(() => {
    if (parentPhoneNumber && parentPhoneNumber.length === 10) {
      const check = checkPattenPhoneNumber(parentPhoneNumber);
      setErrorMessage({
        ...errorMessage,
        PARENT_PHONE: !check ? "กรุณากรอกเบอร์โทรศัพท์ผู้ปกครองให้ถูกต้อง" : "",
      });
    }
  }, [parentPhoneNumber]);

  useEffect(() => {
    if (contactPhoneNumber && contactPhoneNumber.length === 10) {
      const check = checkPattenPhoneNumber(contactPhoneNumber);
      setErrorMessage({
        ...errorMessage,
        CONTACT_PHONE: !check
          ? "กรุณากรอกเบอร์โทรศัพท์บุคคลติดต่อให้ถูกต้อง"
          : "",
      });
    }
  }, [contactPhoneNumber]);

  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      if (result?.data?.data?.getCurrent.fullName !== "") {
        // console.log(
        //   "result?.data?.data?.getCurrent",
        //   result?.data?.data?.getCurrent
        // );
        const [firstName, lastName] =
          result?.data?.data?.getCurrent.fullName?.split(" ") ?? "";
        const decryptTel = result?.data?.data?.getCurrent?.tel
          ? decryptString(result?.data?.data?.getCurrent?.tel)
          : "";
        const decryptIdCard = result?.data?.data?.getCurrent?.idCard
          ? decryptString(result?.data?.data?.getCurrent?.idCard)
          : "";
        // console.log("decryptIdCard", decryptIdCard);
        setIdCard(decryptIdCard);
        setTel(decryptTel);
        setName(firstName || "");
        setLastname(lastName || "");
        setBirthDay(result?.data?.data?.getCurrent?.dob || "");
        setAddress(result?.data?.data?.getCurrent?.address?.address || "");
        setZipCode(result?.data?.data?.getCurrent?.address?.zipcode || "");
        setProvince(result?.data?.data?.getCurrent?.address?.province || "");
        setDistrict(result?.data?.data?.getCurrent?.address?.district || "");
        setSubdistrict(
          result?.data?.data?.getCurrent?.address?.subdistrict || ""
        );
        setIdcardParent(
          idCardFormat(
            result?.data?.data?.getCurrent?.contactParent?.id_card || ""
          ) || ""
        );
        setParentName(
          result?.data?.data?.getCurrent?.contactParent?.firstname || ""
        );
        setParentLastname(
          result?.data?.data?.getCurrent?.contactParent?.lastname || ""
        );
        setParentPhoneNumber(
          result?.data?.data?.getCurrent?.contactParent?.tel || ""
        );
        setParentRelationship(
          result?.data?.data?.getCurrent?.contactParent?.relationship || ""
        );
        setContactName(
          result?.data?.data?.getCurrent?.contactPerson?.firstname || ""
        );
        setContactLastname(
          result?.data?.data?.getCurrent?.contactPerson?.lastname || ""
        );
        setContactPhoneNumber(
          result?.data?.data?.getCurrent?.contactPerson?.tel || ""
        );
        setContactRelationship(
          result?.data?.data?.getCurrent?.contactPerson?.relationship || ""
        );
      }
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  const loadAddress = useCallback(() => {
    MasterDataApi.getAddress().then((res) => {
      if (res.status === 200) {
        setOptionAddress(res.data.data.address);
      }
    });
  }, []);

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  useEffect(() => {
    if (zipCode?.length === 5 && optionAddress.length > 0) {
      const filterProvinceMaster = optionAddress
        .filter((val: any) => val.zipcode === zipCode)
        .map((data: any) => {
          return data;
        });
      const filterProvince = optionAddress
        .filter((val: any) => val.zipcode === zipCode)
        .map((data: any) => {
          return { value: data.province, label: data.province };
        });
      setOptionProviceMaster(filterProvinceMaster);
      setOptionProvice(duplicate(filterProvince));
    } else if (zipCode?.length !== 5) {
      setOptionProviceMaster([]);
      setOptionProvice([]);
      setOptionDistrictMaster([]);
      setOptionDistrict([]);
      setOptionSubdistrict([]);
      setProvince("");
      setDistrict("");
      setSubdistrict("");
    }
  }, [zipCode, optionAddress]);

  const duplicate = (data: any) => {
    if (data.length > 0) {
      return data.filter(
        (el: any, id: number) =>
          id === data.findIndex((ele: any) => ele.value === el.value)
      );
    }
  };

  useEffect(() => {
    if (province) {
      const filterDistrictMaster = optionProviceMaster
        .filter((val: any) => val.province === province)
        .map((data: any) => {
          return data;
        });
      const filterDistrict = optionProviceMaster
        .filter((val: any) => val.province === province)
        .map((data: any) => {
          return { value: data.district, label: data.district };
        });
      setOptionDistrictMaster(filterDistrictMaster);
      setOptionDistrict(duplicate(filterDistrict));
    } else {
      setOptionSubdistrict([]);
      setDistrict("");
      setSubdistrict("");
    }
  }, [province, optionProviceMaster]);

  useEffect(() => {
    if (district) {
      const filterSubdistrict = optionDistrictMaster
        .filter((val: any) => val.district === district)
        .map((data: any) => {
          return { value: data.subdistrict, label: data.subdistrict };
        });
      setOptionSubdistrict(duplicate(filterSubdistrict));
    } else {
      setOptionSubdistrict([]);
      setSubdistrict("");
    }
  }, [district, optionDistrictMaster]);

  const updateProfile = async () => {
    const errors: ErrorMessageType = { ...initStateErrorMessage };

    if (!name) errors.NAME = "ไม่พบชื่อ";
    else errors.NAME = "";
    if (!lastname) errors.LASTNAME = "ไม่พบนามสกุล";
    else errors.LASTNAME = "";
    if (!birthday) errors.BIRTHDAY = "กรุณากรอกวัน/เดือน/ปีเกิด";
    else errors.BIRTHDAY = "";
    if (!address) errors.ADDRESS = "กรุณากรอกที่อยู่";
    else errors.ADDRESS = "";
    if (!zipCode) errors.ZIPCODE = "กรุณากรอกรหัสไปรษณีย์";
    else errors.ZIPCODE = "";
    if (!province) errors.PROVINCE = "กรุณาเลือกจังหวัด";
    else errors.PROVINCE = "";
    if (!district) errors.DISTRICT = "กรุณาเลือกเขต/อำเภอ";
    else errors.DISTRICT = "";
    if (!subdistrict) errors.SUB_DISTRICT = "กรุณาเลือกแขวง/ตำบล";
    else errors.SUB_DISTRICT = "";

    /// ผู้ปกครอง
    if (age < 20) {
      if (!idcardParent) {
        errors.IDCARD_PARENT = "กรุณากรอกเลขบัตรประชาชนผู้ปกครอง";
      } else if (idcardParent && idcardParent?.length < 13) {
        errors.IDCARD_PARENT = "กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก";
      } else if (
        idcardParent &&
        idcardParent?.length === 13 &&
        !chkDigitPid(idcardParent)
      ) {
        errors.IDCARD_PARENT = "กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง";
      } else if (idcardParent === idCard) {
        errors.IDCARD_PARENT = "เลขบัตรประชาชนผู้ปกครองซำ้กับผู้ใช้";
      } else {
        errors.IDCARD_PARENT = "";
      }

      if (!parentName) errors.PARENT_NAME = "กรุณากรอกชื่อผู้ปกครอง";
      else if (parentLastname === lastname && parentName === name)
        errors.PARENT_NAME = "ชื่อผู้ปกครองซำ้กับชื่อผู้ใช้";
      else if (parentLastname === contactLastname && parentName === contactName)
        errors.PARENT_NAME = "ชื่อผู้ปกครองซำ้กับชื่อผู้ติดต่อ";
      else errors.PARENT_NAME = "";

      if (!parentLastname) errors.PARENT_LASTNAME = "กรุณากรอกนามสกุลผู้ปกครอง";
      else errors.PARENT_LASTNAME = "";

      if (!parentPhoneNumber) {
        errors.PARENT_PHONE = "กรุณากรอกเบอร์โทรศัพท์ผู้ปกครอง";
      } else if (parentPhoneNumber && parentPhoneNumber?.length < 10) {
        errors.PARENT_PHONE = "กรุณากรอกเบอร์โทรศัพท์ผู้ปกครองให้ครบ";
      } else if (
        parentPhoneNumber &&
        parentPhoneNumber.length === 10 &&
        !checkPattenPhoneNumber(parentPhoneNumber)
      ) {
        errors.PARENT_PHONE = "กรุณากรอกเบอร์โทรศัพท์ผู้ปกครองให้ถูกต้อง";
      } else if (parentPhoneNumber === tel) {
        errors.PARENT_PHONE = "เบอร์โทรศัพท์ผู้ปกครองซำ้กับผู้ใช้";
      } else {
        errors.PARENT_PHONE = "";
      }
      if (!parentRelationship)
        errors.PARENT_RELATIONSHIP = "กรุณาเลือกความสัมพันธ์กับผู้ปกครอง";
      else errors.PARENT_RELATIONSHIP = "";
    }

    /// บุคคลติดต่อ
    if (!contactName) errors.CONTACT_NAME = "กรุณากรอกชื่อบุคคลติดต่อ";
    else if (contactLastname === lastname && contactName === name)
      errors.CONTACT_NAME = "ชื่อบุคคลติดต่อซำ้กับชื่อผู้ใช้";
    else if (contactLastname === parentLastname && contactName === parentName)
      errors.CONTACT_NAME = "ชื่อบุคคลติดต่อซำ้กับชื่อผู้ปกครอง";
    else errors.CONTACT_NAME = "";

    if (!contactLastname)
      errors.CONTACT_LASTNAME = "กรุณากรอกนามสกุลบุคคลติดต่อ";
    else errors.CONTACT_LASTNAME = "";
    if (!contactPhoneNumber) {
      errors.CONTACT_PHONE = "กรุณากรอกเบอร์โทรศัพท์บุคคลติดต่อ";
    } else if (contactPhoneNumber && contactPhoneNumber?.length < 10) {
      errors.CONTACT_PHONE = "กรุณากรอกเบอร์โทรศัพท์บุคคลติดต่อให้ครบ";
    } else if (
      contactPhoneNumber &&
      contactPhoneNumber.length === 10 &&
      !checkPattenPhoneNumber(contactPhoneNumber)
    ) {
      errors.CONTACT_PHONE = "กรุณากรอกเบอร์โทรศัพท์บุคคลติดต่อให้ถูกต้อง";
    } else if (contactPhoneNumber === tel) {
      errors.CONTACT_PHONE = "เบอร์โทรศัพท์บุคคลติดต่อซำ้กับผู้ใช้";
    } else if (contactPhoneNumber === parentPhoneNumber) {
      errors.CONTACT_PHONE = "เบอร์โทรศัพท์บุคคลติดต่อซำ้กับผู้ปกครอง";
    } else {
      errors.CONTACT_PHONE = "";
    }
    if (!contactRelatiionship)
      errors.CONTACT_RELATIONSHIP = "กรุณาเลือกความสัมพันธ์กับบุคคลติดต่อ";
    else errors.CONTACT_RELATIONSHIP = "";

    const allEmpty = Object.keys(errors).every(
      (key) => errors[key as keyof ErrorMessageType] === ""
    );
    if (!allEmpty) {
      setErrorMessage(errors);
      return;
    }

    const payload = {
      name: `${name} ${lastname}`,
      dob: birthday,
      address: {
        address,
        subdistrict,
        district,
        province,
        zipcode: zipCode,
      },
      contactParent: {
        id_card: idcardParent,
        firstname: parentName,
        lastname: parentLastname,
        tel: parentPhoneNumber,
        relationship: parentRelationship,
      },
      contactPerson: {
        firstname: contactName,
        lastname: contactLastname,
        tel: contactPhoneNumber,
        relationship: contactRelatiionship,
      },
      downpaymentNo: props.downPaymentNo,
    };

    try {
      const res = await PersonalDetailApi.updateAdditionalDetail(payload);
      if (res.status === 200) {
        setLoadingPage(false);
        props.onSubmit && props.onSubmit();
      } else {
        setLoadingPage(false);
        swalError(String(res?.data?.message));
      }
    } catch (error: any) {
      setLoadingPage(false);
      swalError(error?.response?.data?.message || "ไม่สามารถบันทึกได้");
    }
  };

  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage} />
      <div className="pt-3 fw-bold">
        <HeaderText label="กรอกข้อมูลเพิ่มเติม" enableBorder={true} />
      </div>
      <Row className="pt-2">
        <Col xs={12} lg={6}>
          <InputTextField
            style={{ marginTop: "10px" }}
            disabled
            value={name}
            heading="ชื่อจริง"
            helperText={errorMessage.NAME}
          />
        </Col>
        <Col xs={12} lg={6}>
          <InputTextField
            style={{ marginTop: "10px" }}
            disabled
            value={lastname}
            heading="นามสกุล"
            helperText={errorMessage.LASTNAME}
          />
        </Col>
      </Row>
      <Row className="pt-2">
        <Col sm={6} xs={6} lg={6}>
          <InputDatePicker
            dateFormat="DD/MM/YYYY"
            required={true}
            key={"BIRTHDAY"}
            label=""
            value={birthday}
            onClear={() => setBirthDay("")}
            onChange={(e: any) => {
              setBirthDay(moment(e).format("YYYY-MM-DD"));
              setErrorMessage({ ...errorMessage, BIRTHDAY: "" });
            }}
            heading={"วัน/เดือน/ปี (เกิด)"}
            helperText={errorMessage.BIRTHDAY}
            maxDate={maxDate}
            minDate={minDate}
          />
        </Col>
        <Col sm={6} xs={6} lg={6}>
          <InputTextField disabled value={age} heading="อายุ (ปี)" />
        </Col>
      </Row>
      <Row className="pt-2">
        <Col xs={12} className="pt-2">
          <InputTextField
            placeholder="กรอกที่อยู่"
            value={address || ""}
            onchange={(event: any) => {
              setAddress(event.target.value);
              setErrorMessage({ ...errorMessage, ADDRESS: "" });
            }}
            heading="ที่อยู่"
            required
            helperText={errorMessage.ADDRESS}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6} lg={6} className="pt-2">
          <InputTextField
            placeholder="กรอกรหัสไปรษณีย์"
            value={zipCode || ""}
            onchange={(event: any) => {
              if (event.target.value.length <= 5) {
                setZipCode(event.target.value);
                // setCheckZipcode(false)
                setErrorMessage({ ...errorMessage, ZIPCODE: "" });
              }
            }}
            required
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            helperText={errorMessage.ZIPCODE}
          />
        </Col>
        <Col sm={6} xs={6} lg={6} className="pt-2">
          <AutocompleteSelect
            key={"province"}
            options={optionProvice || []}
            value={
              _.find(optionProvice, (val: any) => val.value === province) ||
              null
            }
            placeholder={"เลือกจังหวัด"}
            getOptionLabel={(option: any) => option.label}
            labelId="province"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, PROVINCE: "" });
                setProvince(value.value);
              } else if (reason === "clear") setProvince("");
            }}
            required
            helperText={errorMessage.PROVINCE}
          />
        </Col>
        <Col sm={6} xs={6} lg={6} className="pt-2">
          <AutocompleteSelect
            key={"district"}
            options={optionDistrict || []}
            value={
              _.find(optionDistrict, (val: any) => val.value === district) ||
              null
            }
            placeholder={"เลือกเขต/อำเภอ"}
            getOptionLabel={(option: any) => option.label}
            labelId="district"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, DISTRICT: "" });
                // amplitudeTrack('2_Profile_P_district')
                setDistrict(value.value);
              } else if (reason === "clear") setDistrict("");
            }}
            required
            helperText={errorMessage.DISTRICT}
          />
        </Col>
        <Col sm={6} xs={6} lg={6} className="pt-2">
          <AutocompleteSelect
            key={"subdistrict"}
            options={optionSubDistrict || []}
            value={
              _.find(
                optionSubDistrict,
                (val: any) => val.value === subdistrict
              ) || null
            }
            placeholder={"เลือกแขวง/ตำบล"}
            getOptionLabel={(option: any) => option.label}
            labelId="subdistrict"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, SUB_DISTRICT: "" });
                // amplitudeTrack('2_Profile_P_district')
                setSubdistrict(value.value);
              } else if (reason === "clear") setSubdistrict("");
            }}
            required
            helperText={errorMessage.SUB_DISTRICT}
          />
        </Col>
      </Row>
      {age < 20 && (
        <>
          <Row>
            <Col xs={12} className="pt-2">
              <InputTextField
                placeholder="เลขบัตรประชาชน"
                value={idCardFormat(idcardParent)}
                onchange={(event: any) => {
                  if (event.target.value.length <= 16) {
                    setIdcardParent(event.target.value?.replace(/-/g, ""));
                    // setCheckZipcode(false)
                    setErrorMessage({ ...errorMessage, IDCARD_PARENT: "" });
                  }
                }}
                required
                type="text"
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 17,
                  pattern: "[0-9]*",
                }}
                heading="ชื่อ-สกุลผู้ปกครอง"
                helperText={errorMessage.IDCARD_PARENT}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} lg={6} className="pt-2">
              <InputTextField
                placeholder="กรอกชื่อ"
                value={parentName || ""}
                onchange={(event: any) => {
                  // if (event.target.value) {
                  setParentName(event.target.value);
                  setErrorMessage({ ...errorMessage, PARENT_NAME: "" });
                  // }
                }}
                required
                helperText={errorMessage.PARENT_NAME}
              />
            </Col>
            <Col xs={6} sm={6} lg={6} className="pt-2">
              <InputTextField
                placeholder="กรอกนามสกุล"
                value={parentLastname || ""}
                onchange={(event: any) => {
                  // if (event.target.value) {
                  setParentLastname(event.target.value);
                  setErrorMessage({ ...errorMessage, PARENT_LASTNAME: "" });
                  // }
                }}
                required
                helperText={errorMessage.PARENT_LASTNAME}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={6} lg={6} className="pt-2">
              <InputTextField
                placeholder="เบอร์โทรศัพท์"
                value={parentPhoneNumber || ""}
                onchange={(event: any) => {
                  if (event.target.value.length <= 10) {
                    setParentPhoneNumber(event.target.value);
                    setErrorMessage({ ...errorMessage, PARENT_PHONE: "" });
                  }
                }}
                required
                helperText={errorMessage.PARENT_PHONE}
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Col>
            <Col xs={6} sm={6} lg={6} className="pt-2">
              <AutocompleteSelect
                key={"province"}
                options={optionRelationshipsParent || []}
                value={
                  _.find(
                    optionRelationshipsParent,
                    (val: any) => val.value === parentRelationship
                  ) || null
                }
                placeholder={"เลือกความสัมพันธ์"}
                getOptionLabel={(option: any) => option.label}
                labelId="relationship"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({
                      ...errorMessage,
                      PARENT_RELATIONSHIP: "",
                    });
                    setParentRelationship(value.value);
                  } else if (reason === "clear") setParentRelationship("");
                }}
                required
                helperText={errorMessage.PARENT_RELATIONSHIP}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={6} sm={6} lg={6} className="pt-2">
          <InputTextField
            placeholder="กรอกชื่อ"
            value={contactName || ""}
            onchange={(event: any) => {
              // if (event.target.value) {
              setContactName(event.target.value);
              setErrorMessage({ ...errorMessage, CONTACT_NAME: "" });
              // }
            }}
            required
            heading="บุคคลติดต่อ"
            helperText={errorMessage.CONTACT_NAME}
          />
        </Col>
        <Col xs={6} sm={6} lg={6} style={{ paddingTop: 32 }}>
          <InputTextField
            placeholder="กรอกนามสกุล"
            value={contactLastname || ""}
            onchange={(event: any) => {
              // if (event.target.value) {
              setContactLastname(event.target.value);
              setErrorMessage({ ...errorMessage, CONTACT_LASTNAME: "" });
              // }
            }}
            required
            helperText={errorMessage.CONTACT_LASTNAME}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} lg={6} className="pt-2">
          <InputTextField
            placeholder="เบอร์โทรศัพท์"
            value={contactPhoneNumber || ""}
            onchange={(event: any) => {
              if (event.target.value.length <= 10) {
                setContactPhoneNumber(event.target.value);
                setErrorMessage({ ...errorMessage, CONTACT_PHONE: "" });
              }
            }}
            required
            helperText={errorMessage.CONTACT_PHONE}
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
        </Col>
        <Col xs={6} sm={6} lg={6} className="pt-2">
          <AutocompleteSelect
            key={"province"}
            options={optionRelationships || []}
            value={
              _.find(
                optionRelationships,
                (val: any) => val.value === contactRelatiionship
              ) || null
            }
            placeholder={"เลือกความสัมพันธ์"}
            getOptionLabel={(option: any) => option.label}
            labelId="relationship"
            onChange={(event, value, reason) => {
              if (value) {
                setErrorMessage({ ...errorMessage, CONTACT_RELATIONSHIP: "" });
                setContactRelationship(value.value);
              } else if (reason === "clear") setContactRelationship("");
            }}
            required
            helperText={errorMessage.CONTACT_RELATIONSHIP}
          />
        </Col>
      </Row>

      <Row className="mt-2 pt-2">
        <ButtonCustom
          className="px-1"
          //   active={typeAomDown === 'INSTALLMENT_AND_USE'}
          mode="default"
          btnStyle={{ width: "100%" }}
          textButton="ยืนยัน"
          onClick={() => {
            updateProfile();
          }}
        />
      </Row>
    </div>
  );
}
