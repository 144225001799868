import liff from "@line/liff";
import { useEffect } from "react";
import AuthenApi from "../../api/authen/authen.api";
import { imgs } from "../../constants/images";
import { logout, setClientKey } from "../../utils/app.utils";

export default function Logout() {
  useEffect(() => {
    liff
      .init({
        liffId: `${process.env.REACT_APP_LIFF_ID_LOGOUT}`,
        withLoginOnExternalBrowser: true,
      })
      .then(async () => {
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          setClientKey(profile.userId);
          const res = await AuthenApi.logout();
          if (res.status === 200) {
            logout();
          }
          liff.closeWindow();
        } else {
          liff.login();
          liff.closeWindow();
        }
      })
      .catch((err) => {
        liff.closeWindow();
      });
  }, []);

  return (
    <div style={{}}>
      <img width={390} height={291} src={imgs.logOut} alt={"ออกจากระบบ"} />
    </div>
  );
}
