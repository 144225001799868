import BaseAPI, { key } from "../api";

const path = "/aomdown/app/aomdown";
// const path = '/app/aomdown'
// const path = "http://localhost:3201/app/aomdown";

export default class AomdownApi extends BaseAPI {
  // static getAomdownDetail(): Promise<any> {
  //     return this.api.get(`${path}/getAomdownDetail`).then((res) => res)
  // }

  static getAdminRegister(): Promise<any> {
    return this.api.get(`${path}/getAdminRegister`).then((res) => res);
  }

  static getAdminRegisterNewToken(token: any, keyClient: any): Promise<any> {
    return this.api
      .get(`${path}/getAdminRegister`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": key,
          Authorization: token ? `Bearer ${token}` : ``,
          "client-key": keyClient ? keyClient : "",
        },
      })
      .then((res) => res);
  }

  static getAomdownPDF(payload: string): Promise<any> {
    return this.api.get(`${path}/${payload}/export`).then((res) => res);
  }

  static updateAcceptConditions(): Promise<any> {
    return this.api.post(`${path}/updateAcceptConditions`).then((res) => res);
  }
  // static calculateAomdown(playload: any): Promise<any> {
  //     return this.api.post(`${path}/calculate/amount/downpayment`, playload).then((res) => res);
  // }
  static calculateAomdown(playload: any): Promise<any> {
    return this.api
      .post(`${path}/calculate/downpayment`, playload)
      .then((res) => res);
  }

  static getTime(playload: any): Promise<any> {
    return this.api
      .post(`${path}/calculate/month/downpayment`, playload)
      .then((res) => res);
  }

  static checkStep(): Promise<any> {
    return this.api.get(`${path}/checkStep`).then((res) => res);
  }

  static uploadSignature(payload: any): Promise<any> {
    return this.api.post(`${path}/uploadSignature`, payload).then((res) => res);
  }

  static getDownPaymentNo(): Promise<any> {
    return this.api.get(`${path}/getDownpaymentNo`).then((res) => res);
  }

  static getAomdownDetail(downpayment_no: any): Promise<any> {
    return this.api
      .get(`${path}/getAomdownDetail`, { params: { downpayment_no } })
      .then((res) => res);
  }

  static getValidateUploadSlip(): Promise<any> {
    return this.api.get(`${path}/validate-payment-amount`).then((res) => res);
  }
}
