import BaseAPI from "../api";

const path = "contact/graphql"; // connect server contract-service
// const path = "http://localhost:3037/graphql"; // connect local contract-service

export default class ContractApi extends BaseAPI {
  static uploadIdCardContract(payload: any): Promise<any> {
    return this.api
      .post(`${path}`, {
        query: `
        mutation UploadIdCardContract($input: updateFileInp!){
         uploadIdCardContract(input: $input){
           message
         }
        }
      `,
        variables: {
          input: {
            url: payload.url,
            contractNo: payload.contractNo,
          },
        },
      })
      .then((res) => res);
  }

  static uploadSignatureContract(payload: any): Promise<any> {
    return this.api
      .post(`${path}`, {
        query: `
        mutation UploadSignatureContract($input: updateFileInp!){
         uploadSignatureContract(input: $input){
           message
         }
        }
      `,
        variables: {
          input: {
            url: payload.url,
            contractNo: payload.contractNo,
          },
        },
      })
      .then((res) => res);
  }

  static updateCustomerReceiptByCustomer(payload: any): Promise<any> {
    return this.api
      .post(`${path}`, {
        query: `
        mutation UpdateCustomerReceiptByCustomer($input: CustomerReceiptByCustomerUpdateInp!){
         updateCustomerReceiptByCustomer(input: $input){
           success
           message
         }
        }
      `,
        variables: {
          input: {
            ...payload,
            // contractNo: payload.contractNo || '',
            // downPaymentNo: payload.downPaymentNo || '',
            // channel: payload.channel || '',
            // pickupDateTime: payload.pickupDateTime || '',
            // name: payload.name || '',
            // tel: payload.tel || '',
            // reserveTel: payload.reserveTel || '',
            // address: payload.address || '',
            // zipcode: payload.zipcode || '',
            // province: payload.province || '',
            // district: payload.district || '',
            // subDistrict: payload.subDistrict || '',
            // remarkPickUp: payload.remarkPickUp || '',
          },
        },
      })
      .then((res) => res);
  }

  static getContactDetailCustomer(payload: any): Promise<any> {
    return this.api
      .post(`${path}`, {
        query: `
        query GetContractDetailCustomer($input: GetContractDetail!) {
        getContractDetailCustomer(input: $input) {
          result {
            contractNo
            status
            statusTH
            periods
            type
            transactionDate
            installments {
              contract_no
              type
              payment_due_date
              period_amount
              period
            }
            product {
              item_code
              serial_number
              item_name
            }
            pdfContract
            price
            paid_amount
            paid_rate
            product_img
            tel
          }
          message
        }
      }
      `,
        variables: {
          input: {
            contractNo: payload,
          },
        },
      })
      .then((res) => res);
  }
}
