import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getToken } from "../../utils/app.utils";
import AomdownDetail from "./aomdown-detail/aomdown-detail";
import AdditionalInformation from "./additional-information/additional-information";
import VerifyIdentify from "./verify-identity/verify-identity";
import ReceiveProduct from "./receive-product/receive-product";
import SignContact from "./sign-contact/sign-contact";
import Otp from "./otp/otp";
import liff from "@line/liff";
import AccpteCondition from "./accpet-condition/accpte-condtion";
import PayHistory from "../upload-slip/pay-history";
import InstallmentPayment from "./installment-payment/installment-payment";

export default function AomdownCustomer() {
  const [setpper, setSetpper] = useState<number>(0);
  const [accessTokenLine, setAccessTokenLine] = useState<string>("");
  const [typeAomDownPayment, setTypeAomDown] = useState<string>("");
  const [downPaymentNo, setDownpaymentNo] = useState<string>("");
  const [contractNo, setContractNo] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  useEffect(() => {
    liff
      .init({ liffId: `${process.env.REACT_APP_LIFF_ID_AOMDOWN_DETAIL}` })
      .then(async () => {
        if (liff.isLoggedIn()) {
          const accessTokenLine = (await liff.getAccessToken()) || "";
          setAccessTokenLine(accessTokenLine);
        } else {
          liff.login();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (typeAomDownPayment === "INSTALLMENT_AND_USE") {
      setSetpper(1);
    } else if (typeAomDownPayment === "PAY_THEN_RECEIVE") {
      setSetpper(6);
    }
  }, [typeAomDownPayment]);
  // console.log("phoneNumber", phoneNumber);
  return (
    <div>
      {setpper === 0 && (
        <AomdownDetail
          onClickNext={(e: any) => {
            setTypeAomDown(e);
          }}
          setDownPayMentNo={(e: any) => {
            setDownpaymentNo(e);
          }}
          setContractNo={(e: any) => {
            setContractNo(e);
          }}
          phoneNumber={(e: any) => {
            setPhoneNumber(e);
          }}
          onSetStepper={(e) => {
            e ? setSetpper(e) : setSetpper(2);
          }}
          dowmpaymentNo={downPaymentNo}
          contractNo={contractNo}
        />
      )}
      {setpper === 1 && (
        <AdditionalInformation
          typeAomdown={typeAomDownPayment}
          downPaymentNo={downPaymentNo}
          onSubmit={() => {
            setSetpper(0);
          }}
        />
      )}
      {setpper === 2 && (
        <VerifyIdentify
          contractNo={contractNo}
          onSubmit={() => {
            setSetpper(3);
          }}
        />
      )}
      {setpper === 3 && (
        <AccpteCondition
          typeAomDown={typeAomDownPayment}
          onSubmit={() => {
            setSetpper(4);
          }}
        />
      )}
      {setpper === 4 && (
        <SignContact
          contractNo={contractNo}
          accTokenLine={accessTokenLine}
          onSubmit={() => {
            setSetpper(5);
          }}
        />
      )}
      {setpper === 5 && (
        <Otp
          phoneNumber={phoneNumber}
          accessTokenLine={accessTokenLine}
          onSubmit={() => {
            setSetpper(6);
          }}
        />
      )}
      {setpper === 6 && (
        <ReceiveProduct
          contractNo={contractNo}
          downPaymentNo={downPaymentNo}
          onSetStepper={() => {
            setSetpper(0);
          }}
        />
      )}

      {setpper === 7 && (
        <PayHistory
          key="PayHistory"
          onClickBack={() => {
            setSetpper(0);
          }}
        />
      )}
      {setpper === 8 && (
        <InstallmentPayment
          key="InstallmentPayment"
          contractNo={contractNo}
          onClickBack={() => {
            setSetpper(0);
          }}
        />
      )}
    </div>
  );
}
