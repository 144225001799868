import liff from "@line/liff";
import Download from "@mui/icons-material/FileDownloadOutlined";
import {
  Avatar,
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import HeaderText from "../../../components/header/headerText";
import InputTextField from "../../../components/input/inputTextField";
import Loading from "../../../components/loading/loading";
import { swalError } from "../../../components/notification/swal";
import { colors } from "../../../constants/colors";
import { imgs, onImgError } from "../../../constants/images";
import { numberFormat } from "../../../utils/common";
import { decryptString } from "../../../utils/crypto-js";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import _ from "lodash";
import ContractApi from "../../../api/contract/contract.api";
import ModalCustom from "../../../components/modalCustom/modalCustom";
import moment from "moment";
import { convertToBuddhistYear } from "./utils/convertToBuddhistYear";
import { useHistory } from "react-router-dom";

const ImageStyle = styled(`img`)(({ theme }) => ({
  width: 180,
  height: 180,

  [theme.breakpoints.down("lg")]: {
    width: 170,
    height: 170,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colors.disabledLightGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.themeMainColor,
  },
}));

interface AomdownDetail {
  onClickNext?: (e: any) => void;
  setDownPayMentNo?: (e: any) => void;
  phoneNumber?: (e: any) => void;
  setContractNo?: (e: any) => void;
  contractNo?: string;
  onSetStepper?: (e: any) => void;
  dowmpaymentNo?: string;
}

export default function AomdownDetail(props: AomdownDetail) {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const [loadingPage, setLoadingPage] = useState(false);
  const [selectOptionDownPayment, setSelectOptionDownPayment] =
    useState<string>("");
  const [selectOptionContract, setSelectOptionContract] = useState<string>("");
  const [optionDownPaymentNo, setOptionDownPaymentNo] = useState<any[]>([]);
  const [step, setStep] = useState<Boolean>(false);
  const [typeAomDownPayment, setTypeAomDown] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [signContactComplete, setSignContactComplete] = useState<string>("");
  const formatRoundPayment = (key: string) => {
    switch (key) {
      case "DAY":
        return "รายวัน";
      case "WEEK":
        return "รายสัปดาห์";
      case "MONTH":
        return "รายเดือน";
      default:
        break;
    }
  };
  const getDownPaymentNo = async () => {
    try {
      const result = await AomdownApi.getDownPaymentNo();
      if (result.status === 200) {
        setOptionDownPaymentNo(result.data.data);
      }
    } catch (error: any) {
      // console.log("error", error);
      swalError("Option DownPaymentNo " + error);
    }
  };
  // console.log("optionDownPaymentNo", optionDownPaymentNo);
  useEffect(() => {
    getDownPaymentNo();
    if (props.contractNo) {
      //   console.log("props.contractNo", props.contractNo);
      setSelectOptionContract(props.contractNo);
    } else if (props.dowmpaymentNo) {
      setSelectOptionDownPayment(props.dowmpaymentNo);
    }
  }, []);
  // console.log("props.dowmpaymentNo", props.dowmpaymentNo);
  // console.log("props.contractNo", props.contractNo);
  useEffect(() => {
    if (selectOptionDownPayment) {
      getAomdownDetail();
    }
  }, [selectOptionDownPayment]);

  useEffect(() => {
    if (selectOptionContract) {
      getContactDetailCustomer();
    }
  }, [selectOptionContract]);

  const getAomdownFileDownload = async (downPaymentNo: string) => {
    try {
      setLoadingPage(true);
      const res = await AomdownApi.getAomdownPDF(downPaymentNo);
      if (res.status == 200) {
        liff.openWindow({
          url: res.data.data,
          external: true,
        });
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      console.error("Error downloading file:", error);
    }
  };

  const getAomdownDetail = async () => {
    try {
      setLoadingPage(true);
      // console.log("selectOptionDownPayment", selectOptionDownPayment);
      const res = await AomdownApi.getAomdownDetail(selectOptionDownPayment);

      if (res.status == 200) {
        setStep(true);
        const aomdownDetail = res?.data?.data;
        if (aomdownDetail) {
          const product_down = aomdownDetail?.product_down_payment?.product;
          const data = {
            downpayment_no: decryptString(aomdownDetail?.downpayment_no),
            register_date: moment(aomdownDetail?.created_date, "DD/MM/YYYY")
              .subtract(543, "years")
              .toISOString(),
            name: decryptString(aomdownDetail?.user_info?.general?.fullname),
            idCard: decryptString(aomdownDetail?.id_card),
            product_name: product_down?.text,
            status: aomdownDetail?.status,
            aomdown_price: aomdownDetail?.amount,
            round_payment: aomdownDetail?.round_payment,
            time_payment: aomdownDetail?.time_payment,
            round_amount: aomdownDetail?.round_amount,
            pdfContract: aomdownDetail?.pdfContract,
            paid_amount: aomdownDetail?.paid_amount,
            paid_rate: aomdownDetail?.paid_rate,
            img: aomdownDetail?.product_img,
            price: aomdownDetail?.price,
            createContract: aomdownDetail?.create_contract,
            type_aomdown: aomdownDetail?.type_aomdown,
          };
          if (aomdownDetail?.type_aomdown)
            setTypeAomDown(aomdownDetail?.type_aomdown);
          setData(data);
          if (props.phoneNumber)
            props.phoneNumber(decryptString(aomdownDetail?.tel));
        }
      } else {
        setStep(true);
        swalError(String(res?.data?.message));
      }
      setLoadingPage(false);
    } catch (error: any) {
      setLoadingPage(false);
      setStep(false);
      swalError(String("เกิดข้อผิดพลาดไม่สามารถทำรายการได้"));
      console.error(error);
    }
  };

  const getContactDetailCustomer = async () => {
    try {
      const res = await ContractApi.getContactDetailCustomer(
        selectOptionContract
      );
      if (res.status === 200) {
        if (res.data.data.getContractDetailCustomer.result) {
          const respData = res.data.data.getContractDetailCustomer.result;
          // console.log("respData", respData);
          const data = {
            contractNo: respData?.contractNo,
            register_date: moment(
              respData?.transactionDate,
              "DD/MM/YYYY"
            ).toISOString(),
            product_name: respData?.product?.[0]?.item_name,
            round_payment: 0,
            time_payment: respData?.periods, // จำนวนงวด
            round_amount: respData?.installments
              ? respData?.installments?.[1]?.period_amount
              : 0, // ยอดผ่อนต่องวด,
            pdfContract: respData?.pdfContract,
            status: respData?.status,
            paid_amount: respData?.paid_amount,
            paid_rate: respData?.paid_rate,
            img: respData?.product_img,
            price: respData?.price,
            // createContract: respData?.create_contract,
            tel: respData?.tel,
          };
          setTypeAomDown("สัญญาผ่อนสินค้า");
          if (props.phoneNumber) props.phoneNumber(respData?.tel);
          setData(data);
        }
      }
      setStep(true);
      setLoadingPage(false);
    } catch (error: any) {
      setLoadingPage(false);
      setStep(false);
      swalError(String("เกิดข้อผิดพลาดไม่สามารถทำรายการได้"));
      console.error(error);
    }
  };
  console.log("data", data);
  console.log("selectOptionDownPayment", selectOptionDownPayment);
  console.log("selectOptionContract", selectOptionContract);
  // console.log("typeAomDownPayment", typeAomDownPayment);
  return (
    <div className="fw-bold px-3 py-3">
      <Loading show={loadingPage} />

      <div className="pt-3 fw-bold">
        <HeaderText label="ข้อมูลสัญญา" enableBorder={true} />
      </div>

      <div className="pt-4">
        <AutocompleteSelect
          key={"optionDownPayment"}
          size="medium"
          options={optionDownPaymentNo}
          value={
            _.find(
              optionDownPaymentNo,
              (val: any) =>
                (val.downpayment_no === selectOptionDownPayment &&
                  selectOptionDownPayment !== "") ||
                (val.contract_no === selectOptionContract &&
                  selectOptionContract !== "")
            ) || null
          }
          getOptionLabel={(option: any) => option?.value || ""}
          labelId="optionDownPayment"
          onChange={(event, value, reason) => {
            console.log("reason", reason);
            console.log("value", value);
            if (value) {
              setData([]);
              if (value?.type_aomdown === "สัญญาผ่อนสินค้า") {
                setSelectOptionContract(value?.contract_no);
                setSignContactComplete(value?.status);
                if (value?.status) {
                  setStep(true);
                }
                if (value?.status === "WAIT_SIGNATURE") {
                  setIsOpenModal(true);
                }
              } else {
                setSelectOptionDownPayment(value.downpayment_no);
              }
              if (props.setContractNo)
                props.setContractNo(value?.contract_no || "");
              if (props.setDownPayMentNo)
                props.setDownPayMentNo(value?.downpayment_no || "");
              setTypeAomDown(value.type_aomdown);
            } else if (reason === "clear") {
              setData([]);
              setSelectOptionDownPayment("");
              setSelectOptionContract("");
              setStep(false);
            }
          }}
          heading="เลือกสัญญา"
          placeholder="เลือกสัญญา"
        />
      </div>
      {step && (
        <>
          <div>
            <Row className="pt-3 fw-bold">
              <Col>
                <ButtonCustom
                  mode={"default"}
                  disabled={true}
                  // disabled={data?.status === "ACTIVE" }
                  btnStyle={{
                    width: "100%",
                    height: 40,
                    borderRadius: 8,
                    visibility:
                      data?.status === "ACTIVE" && props.contractNo
                        ? "visible"
                        : "hidden",
                  }}
                  textButton={"ค่างวดชำระ"}
                  onClick={() => props.onSetStepper && props.onSetStepper(8)}
                />
                <Typography
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    visibility:
                      data?.status === "ACTIVE" && props.contractNo
                        ? "visible"
                        : "hidden",
                  }}
                >
                  * ลูกค้าจะสามารถดูค่างวดได้หลังจากระบบประมวลผลเรียบร้อยงับ*
                </Typography>
              </Col>
              <Col>
                <ButtonCustom
                  mode={"default"}
                  btnStyle={{ width: "100%", height: 40, borderRadius: 8 }}
                  textButton={"ประวัติชำระเงิน"}
                  onClick={() => props.onSetStepper && props.onSetStepper(7)}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className="pt-3 fw-bold">
              <Col>
                <InputTextField
                  disabled
                  value={convertToBuddhistYear(data?.register_date)}
                  heading={
                    typeAomDownPayment === "INSTALLMENT_AND_USE"
                      ? "วันที่ลงทะเบียน"
                      : "วันที่ทำสัญญา"
                  }
                />
              </Col>
              <Col>
                <div>
                  {typeAomDownPayment === "สัญญาผ่อนสินค้า"
                    ? "สัญญาผ่อนสินค้า"
                    : typeAomDownPayment === "INSTALLMENT_AND_USE"
                    ? "สัญญาผ่อนไปใช้ไป"
                    : "สัญญาผ่อนจบรับของ"}
                </div>
                <ButtonCustom
                  mode={"default"}
                  btnStyle={{ width: "100%", height: 40, borderRadius: 8 }}
                  endIcon={<Download />}
                  disabled={
                    data?.status === "WAIT_SIGNATURE" ||
                    data?.status === "WAIT_ADMIN_CONFIRM" ||
                    data?.status === "TERMINATED"
                  }
                  textButton={"ดาวน์โหลด"}
                  onClick={() => {
                    if (
                      typeAomDownPayment === "สัญญาผ่อนสินค้า" &&
                      data?.pdfContract
                    ) {
                      liff.openWindow({
                        url: data?.pdfContract,
                        external: true,
                      });
                    } else if (
                      typeAomDownPayment !== "สัญญาผ่อนสินค้า" &&
                      data?.downpayment_no
                    ) {
                      getAomdownFileDownload(data?.downpayment_no);
                    }
                  }}
                />
              </Col>
            </Row>
          </div>

          <Box>
            <div className="pt-1">
              <InputTextField
                style={{ marginTop: "10px" }}
                disabled
                value={data?.product_name || ""}
                heading="สินค้า"
              />
            </div>
            <div className="pt-1">
              <Row>
                <Col>
                  <InputTextField
                    style={{ marginTop: "10px" }}
                    disabled
                    value={
                      typeAomDownPayment === "สัญญาผ่อนสินค้า"
                        ? numberFormat(data?.round_amount, 0, 0)
                        : formatRoundPayment(data?.round_payment)
                    }
                    heading={
                      typeAomDownPayment === "สัญญาผ่อนสินค้า"
                        ? "ยอดผ่อนต่องวด"
                        : "รอบการผ่อน"
                    }
                  />
                </Col>
                <Col>
                  <InputTextField
                    style={{ marginTop: "10px" }}
                    disabled
                    value={data?.time_payment || ""}
                    heading={
                      typeAomDownPayment === "สัญญาผ่อนสินค้า"
                        ? "จำนวนงวด"
                        : "ระยะเวลาผ่อน (เดือน)"
                    }
                  />
                </Col>
              </Row>
            </div>
            {typeAomDownPayment !== "สัญญาผ่อนสินค้า" && (
              <div className="pt-1 fw-bold">
                <InputTextField
                  style={{ marginTop: "10px" }}
                  disabled
                  value={numberFormat(data?.round_amount, 0, 0)}
                  heading="ยอดผ่อนต่อรอบ"
                />
              </div>
            )}
            <div className="pt-2">
              <div className="pb-2 d-flex justify-content-between">
                <div>ชำระแล้ว</div>
                <div>ยอดทั้งหมด</div>
              </div>
              <Box position="relative" width="100%" mt={2}>
                <BorderLinearProgress
                  variant="determinate"
                  value={data?.paid_rate || 0}
                  sx={{
                    width: "100%",
                    height: 20,
                  }}
                />

                {/* aomdown target progress */}
                {data?.type_aomdown === "INSTALLMENT_AND_USE" && (
                  <Box
                    sx={{
                      position: "absolute",
                      display: "grid",
                      justifyItems: "center",
                      top: "50%",
                      left: `${(data?.aomdown_price / data?.price) * 100}%`,
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Typography variant="caption">รับเครื่อง</Typography>
                    <Box
                      sx={{
                        width: "3px",
                        height: "40px",
                        backgroundColor: "orange",
                      }}
                    />
                    <Typography variant="caption">
                      {numberFormat(data?.aomdown_price || 0, 0, 0)}
                    </Typography>
                  </Box>
                )}

                {/* current progress with icon */}
                <Avatar
                  variant="rounded"
                  src={imgs.shibaProgress} // Replace with actual icon URL
                  sx={{
                    position: "absolute",
                    width: 50,
                    height: 50,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 2,
                    left: `${data?.paid_rate || 0}%`,
                  }}
                />
              </Box>

              <div className="pt-1 d-flex justify-content-between">
                <div>{numberFormat(data?.paid_amount, 0, 2)}</div>
                <div>{numberFormat(data?.price, 0, 2)}</div>
              </div>
              {(typeAomDownPayment === "INSTALLMENT_AND_USE" &&
                data?.paid_amount >= data?.aomdown_price &&
                !data?.createContract) ||
              (typeAomDownPayment === "PAY_THEN_RECEIVE" &&
                data?.paid_amount >= data?.price &&
                !data?.createContract) ? (
                <div className="pt-4">
                  <ButtonCustom
                    mode={"default"}
                    btnStyle={{ width: "100%" }}
                    textButton={"กดติดต่อรับเครื่องได้เลย"}
                    onClick={() => {
                      if (props.onClickNext)
                        props.onClickNext(typeAomDownPayment);
                    }}
                  />
                </div>
              ) : (
                <div className="text-center pt-4">
                  <ImageStyle
                    className=""
                    src={data?.img}
                    onError={onImgError}
                  />
                </div>
              )}
              {data?.createContract && data?.status === "SUCCESS_PICKUP" ? (
                <div
                  className="font-20"
                  style={{ color: colors.themeMainColor, textAlign: "center" }}
                >
                  ปิดสัญญาเรียบร้อย รอรับสินค้าได้เลยงับ
                </div>
              ) : data?.createContract ? (
                <div
                  className="font-20"
                  style={{ color: colors.themeMainColor, textAlign: "center" }}
                >
                  กรอกข้อมูลเรียบร้อยสามารถแจ้งแอดมินผ่านแชท SHiBA phone ได้เลย
                </div>
              ) : (
                <></>
              )}
            </div>
          </Box>

          <ModalCustom
            size="sm"
            title={""}
            modalTheme="default"
            onSubmit={() => {
              setIsOpenModal(false);
              props.onSetStepper && props.onSetStepper(2);
              if (props.onClickNext) props.onClickNext(typeAomDownPayment);
            }}
            onClose={() => {
              setIsOpenModal(false);
            }}
            textBtnConfirm={"เซ็นสัญญา"}
            isShowProps={isOpenModal}
            component={
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={imgs.imageSuccess}
                    style={{
                      width: "50%",
                      height: "50%",
                      marginBottom: "20px",
                    }}
                  />
                  <span style={{ textAlign: "center" }}>
                    อีกขั้นตอนเดียวเท่านั้น!🎉 เตรียมรับเครื่องได้เลย✨
                  </span>
                  {/* {data?.status !== 'WAIT_SIGNATURE' && 
                                <>
                                <span style={{ textAlign: 'center' }}>แอดมินกำลังเตรียมสินค้า และจะจัดส่งให้เร็วที่สุด</span>
                                <span style={{ textAlign: 'center' }}>รออีกหนึ่งอึดใจเท่านั้น! 😊</span>
                                </>
                            } */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "left",
                    marginBottom: "20px",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ textAlign: "left" }}>
                    ข้อมูลสัญญา {data?.contractNo}
                  </span>
                  <span style={{ textAlign: "left" }}>
                    วันที่ทำสัญญา {convertToBuddhistYear(data?.register_date)}
                  </span>
                  <span style={{ textAlign: "left" }}>
                    สินค้า {data?.product_name}
                  </span>
                  {/* <span style={{ textAlign: 'left' }}>ความจุ {data?.product_name}</span> */}
                  {/* <span style={{ textAlign: 'left' }}>สี {data?.product_name}</span> */}
                  <span style={{ textAlign: "left" }}>
                    ยอดผ่อนต่องวด {numberFormat(data?.round_amount, 0, 0)} บาท
                  </span>
                  <span style={{ textAlign: "left" }}>
                    จำนวนงวด {data?.time_payment} เดือน
                  </span>
                </div>
              </>
            }
          />
        </>
      )}
    </div>
  );
}
