import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import PersonalDetailApi from "../../../api/personal-detail/personalDetail.api";
import Saving from "../../../assets/icon/piggy";
import ButtonCustom from "../../../components/button/buttonCustom";
import HeaderText from "../../../components/header/headerText";
import InputTextField from "../../../components/input/inputTextField";
import Loading from "../../../components/loading/loading";
import { swalError } from "../../../components/notification/swal";
import {
  changeLastname,
  checkEngLang,
  checkThaiLang,
  chkDigitPid,
  idCardFormat,
  numberFormat,
} from "../../../utils/common";
import { decryptString, encryptString } from "../../../utils/crypto-js";
import AuthenApi from "../../../api/authen/authen.api";
import InputDatePicker from "../../../components/input/inputDatePicker";
import moment from "moment";
import { TYPE_AOMDOWN } from "../../../utils/enum";
import { calculateAge } from "../../../utils/date.utils";
import HelperText from "../../../components/input/helperText";

interface PersonalDetail {
  userDetail: any;
  typeAomdown: string;
  onClickNext?: () => void;
}

const initStateErrorMessage = {
  NAME: ``,
  SURNAME: ``,
  IDCARD: ``,
  BIRTHDAY: ``,
};

// const ImageStyle = styled(`img`)(({ theme }) => ({
//   width: 180,
//   height: 180,

//   [theme.breakpoints.down("lg")]: {
//     width: 150,
//     height: 150,
//   },
// }));

export default function PersonalDetail(props: PersonalDetail) {
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [promotion, setPromotion] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [loadingPage, setLoadingPage] = useState(false);
  const [checkIdCard, setCheckIdCard] = useState<boolean>(false);
  const [disabledField, setDisabledField] = useState<boolean>(false);
  const [birthday, setBirthDay] = useState<string>("");
  const [adminRegister, setAdminRegister] = useState(false);
  const [data, setData] = useState<any>();
  const [age, setAge] = useState<number>(0);

  const today = new Date();
  let minDay = props.typeAomdown === TYPE_AOMDOWN.INSTALLMENT_AND_USE ? 15 : 20;
  const maxDate = new Date(
    today.getFullYear() - minDay,
    today.getMonth(),
    today.getDate()
  );
  const minDate = new Date(
    today.getFullYear() - 60,
    today.getMonth(),
    today.getDate()
  );
  useEffect(() => {
    if (idCard && idCard.length === 16) {
      const check = chkDigitPid(idCard.replace(/-/g, ""));
      if (!check) {
        setCheckIdCard(true);
        const msg: any = {
          IDCARD: !check ? "กรอกรหัสบัตรประชาชนไม่ถูกต้อง" : "",
        };
        setErrorMessage({ ...errorMessage, ...msg });
      } else {
        setCheckIdCard(false);
      }
    }
  }, [idCard]);

  const handleNextStep = async () => {
    const handleErrorMessage = {
      NAME: !checkThaiLang(name) ? "โปรดกรอกชื่อ (ภาษาไทย)" : "",
      SURNAME: !checkThaiLang(surname) ? "โปรดกรอกนามสกุล (ภาษาไทย)" : "",
      IDCARD: !idCard
        ? "โปรดกรอกรหัสบัตรประชาชน"
        : idCard.length < 16
        ? "โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก"
        : checkIdCard
        ? "กรอกรหัสบัตรประชาชนไม่ถูกต้อง"
        : "",
      BIRTHDAY: !birthday ? "โปรดกรอกวัน/เดือน/ปี (เกิด)" : "",
    };
    setErrorMessage({ ...initStateErrorMessage, ...handleErrorMessage });

    if (
      !handleErrorMessage.IDCARD &&
      !handleErrorMessage.NAME &&
      !handleErrorMessage.SURNAME
    ) {
      try {
        setLoadingPage(true);
        const lastName = changeLastname(surname);
        const payload = {
          name: encryptString(name.trim()),
          surname: encryptString(lastName),
          idCard: encryptString(idCard.trim().replace(/-/g, "")),
          promoCode: promotion.trim(),
          dob: encryptString(birthday),
        };
        const res = await PersonalDetailApi.updatePersonalDetail(payload);
        if (res.status === 200) {
          setLoadingPage(false);
          props.onClickNext && props.onClickNext();
        } else {
          setLoadingPage(false);
          swalError(String(res?.data?.message));
        }
        props.onClickNext && props.onClickNext();
      } catch (error: any) {
        swalError(String(error.response.data.data));
        setLoadingPage(false);
      }
    }
  };

  const checkAdminRegister = async () => {
    setLoadingPage(true);
    const res = await AomdownApi.getAdminRegister();
    if (res.status === 200) {
      const data = res.data.data;
      if (data) {
        setAdminRegister(true);
        const fdata = {
          name: decryptString(data?.user_info?.general?.fullname),
          dob: data?.user_info?.general?.dob,
          idCard: decryptString(data?.id_card),
          product_name: data?.product?.text,
          round_payment: data?.downpayment_info?.round_payment,
          time_payment: data?.downpayment_info?.time_payment,
          round_amount: data?.downpayment_info?.round_amount,
          product_img: data?.product_img,
        };
        if (data?.user_info?.general?.dob) {
          setBirthDay(moment(fdata.dob).format("YYYY-MM-DD"));
        }
        setData(fdata);
      }
      setLoadingPage(false);
    } else {
      setLoadingPage(false);
    }
  };

  const formatRoundPayment = (key: string) => {
    switch (key) {
      case "DAY":
        return "รายวัน";
      case "WEEK":
        return "รายสัปดาห์";
      case "MONTH":
        return "รายเดือน";
      default:
        break;
    }
  };

  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      if (result?.data?.data?.getCurrent.fullName !== "") {
        const [firstName, lastName] =
          result?.data?.data?.getCurrent.fullName?.split(" ") ?? "";
        setName(firstName);
        setSurname(lastName);
        if (result?.data?.data?.getCurrent?.dob)
          setBirthDay(result?.data?.data?.getCurrent?.dob);
        setIdCard(idCardFormat(result?.data?.data?.getCurrent?.code));
        setDisabledField(true);
      }
    }
  };

  useEffect(() => {
    checkAdminRegister();
    loadCurrentUser();
  }, []);

  useEffect(() => {
    const age = calculateAge(birthday);
    if (age < 15 && props.typeAomdown === TYPE_AOMDOWN.INSTALLMENT_AND_USE) {
      const handleErrorMessage = {
        // NAME: !checkThaiLang(name) ? "โปรดกรอกชื่อ (ภาษาไทย)" : "",
        // SURNAME: !checkThaiLang(surname) ? "โปรดกรอกนามสกุล (ภาษาไทย)" : "",
        // IDCARD: !idCard ? 'โปรดกรอกรหัสบัตรประชาชน' : idCard.length < 16 ? 'โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก' : checkIdCard ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
        BIRTHDAY: "ผู้ทำสัญญาต้องมีอายุ 15 ปีขึ้นไป",
      };
      setErrorMessage({ ...initStateErrorMessage, ...handleErrorMessage });
    } else if (
      age < 20 &&
      props.typeAomdown === TYPE_AOMDOWN.PAY_THEN_RECEIVE
    ) {
      const handleErrorMessage = {
        // NAME: !checkThaiLang(name) ? "โปรดกรอกชื่อ (ภาษาไทย)" : "",
        // SURNAME: !checkThaiLang(surname) ? "โปรดกรอกนามสกุล (ภาษาไทย)" : "",
        // IDCARD: !idCard ? 'โปรดกรอกรหัสบัตรประชาชน' : idCard.length < 16 ? 'โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก' : checkIdCard ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
        BIRTHDAY: "ผู้ทำสัญญาต้องมีอายุ 20 ปีขึ้นไป",
      };
      setErrorMessage({ ...initStateErrorMessage, ...handleErrorMessage });
    }
    setAge(age || 0);
  }, [birthday]);

  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage} />
      <HeaderText
        label="ลงทะเบียนผ่อน"
        component={<Saving />}
        enableBorder={true}
      />
      <div>
        <div className="pt-2">
          {(adminRegister && (
            <InputTextField
              disabled
              value={`${data?.name}`}
              heading="ชื่อ-นามสกุล"
            />
          )) || (
            <>
              <InputTextField
                value={name}
                heading="ชื่อจริง (ภาษาไทย)"
                onchange={(e: any) => {
                  if (checkThaiLang(e.target.value)) {
                    setName(e.target.value);
                    setErrorMessage({ ...errorMessage, NAME: "" });
                  } else if (e.target.value === "") {
                    setName("");
                  }
                }}
                helperText={errorMessage.NAME}
                required
                placeholder="กรอกชื่อจริง (ภาษาไทย)"
                disabled={disabledField}
              />
              <div className="pt-2">
                <InputTextField
                  value={surname}
                  heading="นามสกุล (ภาษาไทย)"
                  onchange={(e: any) => {
                    if (checkThaiLang(e.target.value)) {
                      setSurname(e.target.value);
                      setErrorMessage({ ...errorMessage, SURNAME: "" });
                    } else if (e.target.value === "") {
                      setSurname("");
                    }
                  }}
                  helperText={errorMessage.SURNAME}
                  required
                  placeholder="กรอกนามสกุล (ภาษาไทย)"
                  disabled={disabledField}
                />
              </div>
            </>
          )}
        </div>
        <div className="pt-2">
          <InputTextField
            disabled={adminRegister || disabledField}
            value={
              adminRegister ? idCardFormat(data?.idCard) : idCardFormat(idCard)
            }
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            heading="เลขบัตรประชาชน"
            onchange={(e: any) => {
              if (e.target.value.length <= 16) {
                setIdCard(e.target.value);
                setErrorMessage({ ...errorMessage, IDCARD: "" });
              }
            }}
            helperText={errorMessage.IDCARD}
            required
            placeholder="กรอกรหัสบัตรประชาชน"
          />
        </div>
        {adminRegister && (
          <>
            <div className="pt-2">
              <InputTextField
                disabled
                value={data?.product_name}
                heading="สินค้า"
              />
            </div>
            <div className="pt-2">
              <Row>
                <Col>
                  <InputTextField
                    disabled
                    value={formatRoundPayment(data?.round_payment)}
                    heading="รอบการผ่อน"
                  />
                </Col>
                <Col>
                  <InputTextField
                    disabled
                    value={data?.time_payment}
                    heading="ระยะเวลาผ่อน (เดือน)"
                  />
                </Col>
              </Row>
            </div>
            <div className="pt-2">
              <InputTextField
                disabled
                value={numberFormat(data?.round_amount, 0, 0)}
                heading="ยอดผ่อนต่อรอบ"
              />
            </div>
          </>
        )}
        <Row className="pt-2">
          <Col>
            <InputDatePicker
              dateFormat="DD/MM/YYYY"
              required={true}
              key={"BIRTHDAY"}
              label=""
              value={birthday}
              onClear={() => setBirthDay("")}
              onChange={(e: any) => {
                setBirthDay(moment(e).format("YYYY-MM-DD"));
                setErrorMessage({ ...errorMessage, BIRTHDAY: "" });
              }}
              heading={"วัน/เดือน/ปี (เกิด)"}
              helperText={errorMessage.BIRTHDAY}
              maxDate={maxDate}
              minDate={minDate}
            />
          </Col>
          <Col>
            <InputTextField disabled value={age} heading="อายุ (ปี)" />
          </Col>
        </Row>
        {!adminRegister && (
          <div className="pt-2">
            <InputTextField
              value={promotion}
              heading="รหัสโปรโมชั่น (ถ้ามี)"
              onchange={(e: any) => {
                if (
                  checkEngLang(e.target.value) &&
                  e.target.value.length <= 15
                ) {
                  setPromotion(e.target.value);
                } else if (e.target.value === "") {
                  setPromotion("");
                }
              }}
              placeholder="กรอกรหัสโปรโมชัน"
            />
          </div>
        )}
        <div className="pt-4">
          <ButtonCustom
            mode={"default"}
            btnStyle={{ width: "100%" }}
            disabled={
              name === "" || surname === "" || idCard === "" || birthday === ""
            }
            textButton={adminRegister ? "ถัดไป" : "ยืนยันลงทะเบียน"}
            onClick={() => {
              handleNextStep();
            }}
          />
        </div>
      </div>
    </div>
  );
}
