import BaseAPI from "../api";

const pathRegister = "/register/app/authen";
const pathAuthen = "authen/graphql";
// const pathAuthen = "http://localhost:3031/graphql";

interface register {
  type: string;
  prefix: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  chatcone?: {
    social_id?: string | null;
    token?: string | null;
    channel_id?: string | null;
  } | null;
  amplitudeId?: string;
  walkin?: boolean;
}
interface cardLocationInterface {
  type: string;
}

interface loginInterface {
  phoneNumber?: string;
  otp?: string;
  accessTokenLine?: string;
  clientKey?: string;
}

export default class AuthenApi extends BaseAPI {
  static registerCustomer(payload: register): Promise<any> {
    return this.api
      .post(`${pathRegister}/register-customer`, payload)
      .then((res) => res);
  }

  static registerBeforeVerify(payload: any): Promise<any> {
    return this.api
      .post(`${pathRegister}/register-before-verify`, payload)
      .then((res) => res);
  }

  static cardLocation(payload: cardLocationInterface): Promise<any> {
    return this.api
      .post(`/register/admin/authen/branchRegis/options`, payload)
      .then((res) => res);
  }

  static login(payload: loginInterface): Promise<any> {
    return this.api
      .post(`${pathAuthen}`, {
        query: `
        mutation UserLogin($input: AuthenCustomerInput!){
         userLogin(input: $input){
           refreshToken
           clientKey
           accessToken
           message
         }
        }
      `,
        variables: {
          input: {
            phoneNumber: payload.phoneNumber,
            otp: payload.otp,
            accessTokenLine: payload.accessTokenLine,
            clientKey: payload.clientKey,
          },
        },
      })
      .then((res) => res);
  }

  static getUser(): Promise<any> {
    return this.api
      .post(`${pathAuthen}`, {
        query: `
        query GetCurrent {
          getCurrent {
            tel
            code
            fullName
            dob
            idCard
            address {
              address
              subdistrict
              district
              province
              zipcode
            }
            contactParent {
              id_card
              firstname
              lastname
              tel
              relationship
            }
            contactPerson {
              firstname
              lastname
              tel
              relationship
            }
          }
        }
      `,
      })
      .then((res) => res);
  }
  // address
  // contactParent
  // contactPerson
  static verify(payload: string): Promise<any> {
    return this.api
      .post(`${pathAuthen}`, {
        query: `
        mutation VerifyPartner($input: AuthenCustomerAccessTokenLineInput!) {
         verifyPartner(input: $input)
        }
      `,
        variables: {
          input: {
            accessTokenLine: payload,
          },
        },
      })
      .then((res) => res);
  }

  static logout(): Promise<any> {
    return this.api
      .post(`${pathAuthen}`, {
        query: `
        mutation revokeUserCustomerSessionByKey{
         revokeUserCustomerSessionByKey
        }
      `,
      })
      .then((res) => res);
  }
}
