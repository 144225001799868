import { routeName } from "../routes/routers-name";

export const defaultMenu: any = {
  REGISTER: {
    key: "REGISTER",
    name: "Register",
    textName: "Register",
    path: routeName.register,
  },
  // AOM_DOWN: { key: 'AOM_DOWN', name: 'AomDown', textName: 'AomDown', path: routeName.aomDown },
  REGISTER_AOM_DOWN: {
    key: "REGISTER_AOM_DOWN",
    name: "RegisterAomDown",
    textName: "RegisterAomDown",
    path: routeName.registerAomDown,
  },
  AOM_DOWN_DETAIL: {
    key: "AOM_DOWN_DETAIL",
    name: "AomDownDetail",
    textName: "AomDownDetail",
    path: routeName.aomDownDetail,
  },
  UPLOAD_SLIP: {
    key: "UPLOAD_SLIP",
    name: "UploadSlip",
    textName: "UploadSlip",
    path: routeName.paySlip,
  },
  LOGIN: {
    key: "LOGIN",
    name: "Login",
    textName: "Login",
    path: routeName.login,
  },
  LOGOUT: {
    key: "LOGOUT",
    name: "Logout",
    textName: "Logout",
    path: routeName.logout,
  },
  PERSONAL_INFORMATION: {
    key: "PERSONAL_INFORMATION",
    name: "PersonalInfomation",
    textName: "PersonalInfomation",
    path: routeName.personalInfomation,
  },
  DOWNLOAD_QRCODE: {
    key: "DOWNLOAD_QRCODE",
    name: "DownloadQrCode",
    textName: "DownloadQrCode",
    path: routeName.downloadQrCode,
  },
};
