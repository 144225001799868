import liff from "@line/liff";
import { useEffect, useState } from "react";
import AuthenApi from "../../api/authen/authen.api";
import ButtonCustom from "../../components/button/buttonCustom";
import { swalError, swalWarning } from "../../components/notification/swal";

import { colors } from "../../constants/colors";
import { imgs } from "../../constants/images";

import { Box, Typography, Paper, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import InputTextField from "../../components/input/inputTextField";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../api/aomdown/aomdown.api";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Loading from "../../components/loading/loading";

interface uploadSlip {
  onClickBack?: () => void;
  onClickNext?: () => void;
  onChangeScreen?: (e: any) => void;
  onSetAmount?: (e: any) => void;
  setContractNo?: (e: any) => void;
  contractNo?: string;
}
const initStateMessaageError = {
  ERROR: ``,
};

export default function PaymentMenu(props: uploadSlip) {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [setpper, setSetpper] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState(initStateMessaageError);
  const [aomdownSuccess, setAomDownSuccess] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledPayment, setDisabledPayment] = useState<boolean>(false);
  const loadCurrentUser = async () => {
    try {
      const result = await AuthenApi.getUser();
      if (result.status === 200) {
        setFirstName(result?.data?.data?.getCurrent?.fullName);
      }
      if (result.data.errors) {
        swalWarning(
          "อัพโหลดสลิป",
          "ระบบไม่พบข้อมูลผู้ใช้ กรุณาเข้าสู่ระบบก่อนค่ะ",
          closePage
        );
      }
    } catch (error) {
      swalError("loadCurrentUser : " + error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim(); // ตัดช่องว่างที่ไม่จำเป็น
    const numericValue = Number(value);
    console.log("numericValue", numericValue);
    setAmount(numericValue);
    const isNumber = /^[0-9]+$/;
    const isInt = /^\d+$/;
    console.log("value", value);
    if (!value) {
      setErrorMessage({ ERROR: "กรุณากรอกจำนวนเงิน" });
    } else if (value && numericValue < 50) {
      setErrorMessage({ ERROR: "กรุณาชำระเงินยอดขั้นต่ำ 50 บาท" });
      // setAmount(0); // รีเซ็ตค่า
    } else if (
      value &&
      !isNumber.test(value.toString()) &&
      _.isNaN(numericValue)
    ) {
      setErrorMessage({ ERROR: "กรุณากรอกจำนวนเงินให้ถูกต้อง" });
    } else if (!isInt.test(value.toString())) {
      setErrorMessage({ ERROR: "ไม่สามารถกรอกเลขทศนิยมได้" });
    } else {
      setErrorMessage({ ...initStateMessaageError }); // ล้างข้อความแสดงข้อผิดพลาด

      // setAmount(0); // รีเซ็ตค่า
    }
  };

  const getDownPaymentNo = async () => {
    try {
      const result = await AomdownApi.getDownPaymentNo();
      if (result.status === 200) {
        // console.log("result.data.data", result.data.data);
        //   setOptionDownPaymentNo(result.data.data);
        const contactFilter = result.data.data?.find(
          (data: any) =>
            data?.status === "ACTIVE" &&
            data?.type_aomdown === "สัญญาผ่อนสินค้า"
        );
        // console.log("contactFilter", contactFilter);
        if (contactFilter && props.setContractNo)
          props.setContractNo(contactFilter?.contract_no);
        const checkAomdownRemain = result.data.data?.find(
          (data: any) =>
            // (data?.type_aomdown === "INSTALLMENT_AND_USE" || data?.type_aomdown === "INSTALLMENT_AND_USE"  )&&
            data?.remain_amount > 0
        );
        // console.log("checkAomdownSuccess", checkAomdownRemain);
        const checkAomdownStatus = result.data?.data?.find(
          (data: any) =>
            data?.status === "WAIT_SIGNATURE" ||
            data?.status === "WAIT_ADMIN_CONFIRM"
        );
        // console.log("checkAomdownStatus", checkAomdownStatus);
        if (checkAomdownRemain && !checkAomdownStatus) setAomDownSuccess(false);
        else setAomDownSuccess(true);
      }
    } catch (error: any) {
      swalError("Option DownPaymentNo " + error);
    }
  };

  const onFetchValidateUpload = async () => {
    setLoading(true);
    try {
      const res = await AomdownApi.getValidateUploadSlip();
      if (res.status === 200) {
        setDisabledPayment(res.data?.data?.validatePaymentAmount || false);
      }
    } catch (error) {
      console.log("error", error);
      setDisabledPayment(true);
      if (!aomdownSuccess)
        swalWarning(
          "ชำระออมดาวน์ครบแล้ว หากต้องการชำระค่างวด กรุณาติดต่อแอดมินได้เลยงับ"
        );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadCurrentUser();
    getDownPaymentNo();
    // getBankDetail();
    // onFetchValidateUpload();
  }, []);
  useEffect(() => {
    onFetchValidateUpload();
  }, [aomdownSuccess]);

  const closePage = async () => {
    liff.closeWindow();
  };
  const onSubmit = () => {
    if (!props.onChangeScreen) {
      console.error("props.onChangeScreen is not defined");
      return;
    }

    // if (selectedOption === '') {
    //     swalError("กรุณาเลือกตัวเลือก")
    //     return;
    // }

    if (props.onSetAmount) {
      props.onSetAmount(amount);
    }

    props.onChangeScreen(selectedOption);
  };

  const handleSelect = (option: string) => {
    setSelectedOption(option);
  };
  // console.log("disabledPayment", disabledPayment);
  // console.log("aomdownSuccess", aomdownSuccess);
  return (
    <div>
      <Loading show={loading}></Loading>
      <Box
        sx={{
          maxWidth: 500,
          margin: "auto",
          padding: 3,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <div>
            <Typography fontSize={14} style={{ color: colors.black }}>
              สวัสดีคุณ
            </Typography>
            <strong>{firstName}</strong>
          </div>

          {/* <hr></hr> */}
        </Box>
        <Box>
          <Row className="pt-3 fw-bold">
            <Col>
              <ButtonCustom
                mode={"default"}
                disabled={true}
                // disabled={data?.status === "ACTIVE" }
                btnStyle={{
                  width: "100%",
                  height: 40,
                  borderRadius: 8,
                  visibility: _.isEmpty(props.contractNo)
                    ? "hidden"
                    : "visible",
                }}
                textButton={"ค่างวดชำระ"}
                onClick={() =>
                  props.onChangeScreen && props.onChangeScreen("installment")
                }
              />
              <Typography
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  visibility: _.isEmpty(props.contractNo)
                    ? "hidden"
                    : "visible",
                }}
              >
                * ลูกค้าจะสามารถดูค่างวดได้หลังจากระบบประมวลผลเรียบร้อยงับ*
              </Typography>
            </Col>
            <Col>
              <ButtonCustom
                mode={"default"}
                btnStyle={{ width: "100%", height: 40, borderRadius: 8 }}
                textButton={"ประวัติชำระเงิน"}
                onClick={() =>
                  props.onChangeScreen && props.onChangeScreen("history")
                }
              />
            </Col>
          </Row>
          {/* </div> */}
        </Box>
        <hr />
        {!aomdownSuccess && disabledPayment && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <div>
              <Typography
                variant="body2"
                fontSize={14}
                style={{ color: colors.black }}
              >
                ชำระออมดาวน์ครบแล้ว หากต้องการชำระค่างวด
                กรุณาติดต่อแอดมินได้เลยงับ
              </Typography>
            </div>
          </Box>
        )}
        {aomdownSuccess && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <div>
              <Typography
                variant="body2"
                fontSize={14}
                style={{ color: colors.black }}
              >
                !! สามารถกดติดต่อรับเครื่องได้เลยที่หน้าข้อมูลสัญญา !!
              </Typography>
            </div>
          </Box>
        )}
        <div
          style={{
            visibility:
              aomdownSuccess || disabledPayment ? "hidden" : "visible",
          }}
        >
          <Box className="pt-2">
            <Typography
              fontSize={14}
              style={{ marginLeft: "2px", color: colors.black }}
            >
              กรอกจำนวนที่ต้องการชำระ *
            </Typography>
            <InputTextField
              // value={amount}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              style={{ width: "100%", height: "40px", borderRadius: 0 }}
              onchange={handleChange}
              helperText={errorMessage.ERROR}
              placeholder="กรอกจำนวนที่ต้องการชำระ"
              required
            />
            {/* <Typography fontSize={14} style={{ marginLeft: "2px", color: colors.danger }}>
                    *กรุณาชำระเงินยอดขั้นต่ำ 50 บาท
                </Typography> */}
          </Box>
          <Box className="pt-4">
            <Typography variant="body1">ช่องทางการชำระ: *</Typography>
            <hr style={{ borderColor: colors.themeMainColor }} />
            <Grid container spacing={2}>
              {/* Option 1: ชำระเงินด้วย QR CODE */}
              <Grid item xs={12}>
                <Paper
                  elevation={selectedOption === "qr" ? 3 : 1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 2,
                    border:
                      selectedOption === "qr"
                        ? `2px solid ${colors.themeForthColor}`
                        : "1px solid #ccc",
                    backgroundColor: colors.pink_12,
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  onClick={() => handleSelect("qr")}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={imgs.thaiQrIcon}
                      style={{ width: "40px", marginRight: "10px" }}
                      alt="thaiQrIcon"
                    />
                    <Box>
                      <Typography variant="body1">
                        ชำระเงินด้วย QR CODE
                      </Typography>
                      <Typography variant="caption" color={colors.danger}>
                        เมื่อชำระผ่าน QR ระบบจะปรับยอดอัตโนมัติทันที
                      </Typography>
                    </Box>
                  </Box>
                  {selectedOption === "qr" && (
                    <CheckCircleIcon sx={{ color: colors.themeForthColor }} />
                  )}
                </Paper>
              </Grid>

              {/* Option 2: โอนผ่านเลขบัญชีธนาคาร */}
              <Grid item xs={12}>
                <Paper
                  elevation={selectedOption === "bank" ? 3 : 1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 2,
                    border:
                      selectedOption === "bank"
                        ? `2px solid ${colors.themeForthColor}`
                        : "1px solid #ccc",
                    backgroundColor: colors.pink_12,
                    cursor: "pointer",
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    handleSelect("bank");
                    setSetpper(3);
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <AccountBalanceIcon sx={{ color: colors.themeForthColor, marginRight: 1 }} /> */}
                    <img
                      src={imgs.bankIcon}
                      style={{ width: "25px", marginRight: "10px" }}
                      alt="thaiQrIcon"
                    />
                    <Box>
                      <Typography variant="body1">
                        โอนผ่านเลขบัญชีธนาคาร
                      </Typography>
                      <Typography variant="caption" color={colors.danger}>
                        เมื่อชำระผ่านเลขบัญชีธนาคาร <br />
                        ระบบตรวจสอบล่าช้า 1 - 5 นาที ก่อนปรับยอด
                      </Typography>
                    </Box>
                  </Box>
                  {selectedOption === "bank" && (
                    <CheckCircleIcon sx={{ color: colors.themeForthColor }} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box className="pt-5">
            <ButtonCustom
              mode={"default"}
              btnStyle={{
                width: "100%",
                height: "auto",
                borderRadius: 8,
                // BorderColor:while,
                backgroundColor: colors.themeForthColor, // สีชมพูแบบในภาพ
                color: "white",
                padding: "0 16px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 1)", // เพิ่มเงา
              }}
              textButton={"ยืนยัน"}
              disabled={
                amount === 0 || selectedOption === "" || !!errorMessage.ERROR
              }
              onClick={onSubmit}
              // onClick={() => {
              //     if (props.onChangeScreen) {
              //         console.log("onSetAmount", amount);
              //         if (selectedOption != '') {
              //             props.onChangeScreen(selectedOption);
              //             props.onSetAmount && props.onSetAmount(amount)
              //         }
              //     }
              // }}
            />
          </Box>
        </div>
      </Box>
    </div>
  );
}
