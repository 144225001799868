export const routeName = {
  register: "/register",
  registerAomDown: "/register-aom-down",
  aomDownDetail: "/aom-down-detail",
  paySlip: "/payslip",
  login: "/login",
  logout: "/logout",
  personalInfomation: "/personal-infomation",
  verifyIdentify: "/verify-identify",
  downloadQrCode: "/download-qrcode",
};
