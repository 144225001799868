import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import warningIcon from "../../assets/icon/warning.svg";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const CustomTimePicker = styled(Box)(({ theme }) => ({
  '.MuiOutlinedInput-input': {
    '-webkit-text-fill-color': 'unset !important',
    paddingRight: '3rem !important',
    '&::placeholder': {
      color: colors.black_60,
      opacity: 1
    }
  },
  '.MuiInputAdornment-root': {
    height: 'auto',
    width: 'max-content',
    margin: 0,
    '.MuiButtonBase-root': {
      margin: 0,
      color: colors.themeSecondColor,
      '&.Mui-disabled': {
        color: colors.disabledGray
      },
    },
  },
  '&.has-textfield': {
    '&.date-picker-error': {
      marginBottom: '24px'
    },
    '.MuiFormControl-root': {
      pointerEvents: 'none'
    },
    '.MuiButtonBase-root': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      width: '100%',
      height: 0,
      backgroundColor: `${colors.transparent} !important`,
      justifyContent: 'flex-end',
      padding: '1rem',
      '.MuiTouchRipple-root': {
        display: 'none !important'
      }
    },
    '.MuiInputAdornment-root': {
      '.MuiButtonBase-root': {
        '&.Mui-disabled': {
          color: colors.black_25
        },
      },
    },
    '&.allow-clear-value': {
      '.MuiInputAdornment-root': {
        '.MuiButtonBase-root': {
          opacity: 0
        }
      }
    },
    '.icon-clear-value': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '1rem',
      color: colors.black_60
    },
    '.MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: '-24px'
    },
  }
}))

interface TimePickerProps {
  onChange: (time: string) => void;
  initialTime?: string;
  helperText?: any;
}

const TimePicker: React.FC<TimePickerProps> = ({ onChange, initialTime = "12:00", helperText }) => {
  const [selectedHour, setSelectedHour] = useState(initialTime.split(":")[0]);
  const [selectedMinute, setSelectedMinute] = useState(initialTime.split(":")[1]);

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const hour = event.target.value;
    setSelectedHour(hour);
    onChange(`${hour}:${selectedMinute}`);
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const minute = event.target.value;
    setSelectedMinute(minute);
    onChange(`${selectedHour}:${minute}`);
  };

  const generateOptions = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (_, i) => (start + i).toString().padStart(2, "0"));

  const selectStyle = {
    border: "none",
    outline: "none",
    background: "transparent",
    fontSize: "16px",
    appearance: "none" as const, // For cross-browser compatibility
    padding: "4px",
    width: 45
  };

  // change border style according to alert
  const borderStyle = helperText ? `2px solid ${colors.red}` : `1px solid ${colors.grayInputFieldBorder}`; 

  return (
    <>
      <div style={{ paddingLeft: 13 , display: "flex", height: 40, gap: "10px", alignItems: "center", border: borderStyle, borderRadius: 8, backgroundColor: colors.transparent }}>
        <select value={selectedHour} onChange={handleHourChange} style={selectStyle}>
          <option key="none" value="">{""}</option>
          {generateOptions(9,18).map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </select>
        :
        <select value={selectedMinute} onChange={handleMinuteChange} style={selectStyle}>
          <option key="none" value="">{""}</option>
          {generateOptions(0,61).map((minute) => (
            <option key={minute} value={minute}>
              {minute}
            </option>
          ))}
        </select>
        <AccessAlarmIcon className="mr-2" style={{ fontSize: 30, color: colors.themeMainColor }} />
      </div>
      {helperText && (
        <div className="" style={{ display: 'flex', textAlign: "left", color: colors.red, fontSize: 12, paddingLeft: 13, marginTop: 20 }}>
          <img src={warningIcon} width={12}></img>&thinsp;{helperText}
        </div>
      )}
    </>
  );
};

export default TimePicker;
