import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import HeaderText from "../../../components/header/headerText";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../components/input/inputTextField";
import InputDatePicker from "../../../components/input/inputDatePicker";
import { TYPE_AOMDOWN } from "../../../utils/enum";
import { calculateAge } from "../../../utils/date.utils";
import moment from "moment";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import _ from "lodash";
import { colors } from "../../../constants/colors";
import ButtonCustom from "../../../components/button/buttonCustom";
import ModalCustom from "../../../components/modalCustom/modalCustom";
import { imgs } from "../../../constants/images";
import TimePicker from "../../../components/input/InputTimePicker";
import MasterDataApi from "../../../api/masterdata/masterdata.api";
import ContractApi from "../../../api/contract/contract.api";
import { swalError } from "../../../components/notification/swal";
import { checkPattenPhoneNumber } from "../../../utils/common";
import { decryptString } from "../../../utils/crypto-js";
import AuthenApi from "../../../api/authen/authen.api";

const initStateErrorMessage = {
  PHONE_NUMBER: ``,
  PHONE_NUMBER_RESERVE: ``,
  NAME: ``,
  LASTNAME: ``,
  ADDRESS: "",
  ZIPCODE: "",
  PROVINCE: "",
  DISTRICT: "",
  SUB_DISTRICT: "",
  DATE: "",
  TIME: "",
};

interface receiveProduct {
  contractNo: string;
  downPaymentNo: string;
  onSetStepper?: () => void;
}

interface UpdateData {
  contractNo: string;
  downPaymentNo: string;
  channel: string;
  name?: string;
  tel?: string;
  reserveTel?: string;
  address?: string;
  zipcode?: string;
  province?: string;
  district?: string;
  subDistrict?: string;
  remarkPickUp?: string;
  pickupDateTime?: string;
}

type ErrorMessageType = typeof initStateErrorMessage;

export default function ReceiveProduct(props: receiveProduct) {
  const today = new Date();
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const [loadingPage, setLoadingPage] = useState(false);
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [typeReceive, setTypeReceive] = useState<string>("จัดส่งตามที่อยู่");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneNumberReserve, setPhoneNumberReserve] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [subdistrict, setSubdistrict] = useState<string>("");
  const [date, setDate] = useState<string>("");

  const [optionAddress, setOptionAddress] = useState<any[]>([]);
  const [optionProvice, setOptionProvice] = useState<any[]>([]);
  const [optionProviceMaster, setOptionProviceMaster] = useState<any[]>([]);
  const [optionDistrict, setOptionDistrict] = useState<any[]>([]);
  const [optionDistrictMaster, setOptionDistrictMaster] = useState<any[]>([]);
  const [optionSubDistrict, setOptionSubdistrict] = useState<any[]>([]);

  const [note, setNote] = useState<string>("");
  const [time, setTime] = useState("");

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      if (result?.data?.data?.getCurrent.fullName !== "") {
        const [firstName, lastName] =
          result?.data?.data?.getCurrent.fullName?.split(" ") ?? "";
        const decryptTel = result?.data?.data?.getCurrent?.tel
          ? decryptString(result?.data?.data?.getCurrent?.tel)
          : "";
        setPhoneNumber(decryptTel);
        setName(firstName || "");
        setLastname(lastName || "");
        // setAddress(result?.data?.data?.getCurrent?.address?.address || "");
        // setZipCode(result?.data?.data?.getCurrent?.address?.zipcode || "");
        // // setProvince(result?.data?.data?.getCurrent?.address?.province || "");
        // setDistrict(result?.data?.data?.getCurrent?.address?.district || "");
        // setSubdistrict(
        //   result?.data?.data?.getCurrent?.address?.subdistrict || ""
        // );
      }
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  const loadAddress = useCallback(() => {
    MasterDataApi.getAddress().then((res) => {
      if (res.status === 200) {
        setOptionAddress(res.data.data.address);
      }
    });
  }, []);

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  useEffect(() => {
    if (zipCode?.length === 5 && optionAddress.length > 0) {
      const filterProvinceMaster = optionAddress
        .filter((val: any) => val.zipcode === zipCode)
        .map((data: any) => {
          return data;
        });
      const filterProvince = optionAddress
        .filter((val: any) => val.zipcode === zipCode)
        .map((data: any) => {
          return { value: data.province, label: data.province };
        });
      setOptionProviceMaster(filterProvinceMaster);
      setOptionProvice(duplicate(filterProvince));
    } else if (zipCode?.length !== 5) {
      setOptionProviceMaster([]);
      setOptionProvice([]);
      setOptionDistrictMaster([]);
      setOptionDistrict([]);
      setOptionSubdistrict([]);
      setProvince("");
      setDistrict("");
      setSubdistrict("");
    }
  }, [zipCode, optionAddress]);

  useEffect(() => {
    if (phoneNumber && phoneNumber.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumber);
      setErrorMessage({
        ...errorMessage,
        PHONE_NUMBER: !check
          ? "กรุณากรอกเบอร์โทรศัพท์ที่ติดต่อได้สำหรับการส่งสินค้าให้ถูกต้อง"
          : "",
      });
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (phoneNumberReserve && phoneNumberReserve.length === 10) {
      const check = checkPattenPhoneNumber(phoneNumberReserve);
      setErrorMessage({
        ...errorMessage,
        PHONE_NUMBER_RESERVE: !check
          ? "กรุณากรอกเบอร์โทรศัพท์ติดต่อสำรองให้ถูกต้อง"
          : "",
      });
    }
  }, [phoneNumberReserve]);

  const duplicate = (data: any) => {
    if (data.length > 0) {
      return data.filter(
        (el: any, id: number) =>
          id === data.findIndex((ele: any) => ele.value === el.value)
      );
    }
  };

  useEffect(() => {
    if (province) {
      const filterDistrictMaster = optionProviceMaster
        .filter((val: any) => val.province === province)
        .map((data: any) => {
          return data;
        });
      const filterDistrict = optionProviceMaster
        .filter((val: any) => val.province === province)
        .map((data: any) => {
          return { value: data.district, label: data.district };
        });
      setOptionDistrictMaster(filterDistrictMaster);
      setOptionDistrict(duplicate(filterDistrict));
    } else {
      setOptionSubdistrict([]);
      setDistrict("");
      setSubdistrict("");
    }
  }, [province, optionProviceMaster]);

  useEffect(() => {
    if (district) {
      const filterSubdistrict = optionDistrictMaster
        .filter((val: any) => val.district === district)
        .map((data: any) => {
          return { value: data.subdistrict, label: data.subdistrict };
        });
      setOptionSubdistrict(duplicate(filterSubdistrict));
    } else {
      setOptionSubdistrict([]);
      setSubdistrict("");
    }
  }, [district, optionDistrictMaster]);
  const handleTimeChange = (time: string) => {
    setTime(time);

    setErrorMessage({ ...errorMessage, TIME: "" });
  };

  const isValidTime = (value: string) => {
    // Regular expression to match "HH:mm" format (24-hour clock)
    const timeRegex = /^([01]\d|2[0-3]):[0-5]\d$/;

    if (!timeRegex.test(time)) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const errors: ErrorMessageType = { ...initStateErrorMessage };
    ////จัดส่งตามที่อยู่
    if (typeReceive === "จัดส่งตามที่อยู่" && !phoneNumber)
      errors.PHONE_NUMBER =
        "กรุณากรอกเบอร์โทรศัพท์ที่ติดต่อได้สำหรับการส่งสินค้า";
    else if (phoneNumber && phoneNumber?.length < 10) {
      errors.PHONE_NUMBER =
        "กรุณากรอกเบอร์โทรศัพท์ที่ติดต่อได้สำหรับการส่งสินค้าให้ครบ";
    } else if (
      phoneNumber &&
      phoneNumber.length === 10 &&
      !checkPattenPhoneNumber(phoneNumber)
    ) {
      errors.PHONE_NUMBER =
        "กรุณากรอกเบอร์โทรศัพท์ที่ติดต่อได้สำหรับการส่งสินค้าให้ถูกต้อง";
    } else errors.PHONE_NUMBER = "";

    if (
      typeReceive === "จัดส่งตามที่อยู่" &&
      phoneNumberReserve &&
      phoneNumber === phoneNumberReserve
    )
      errors.PHONE_NUMBER_RESERVE = "*เบอร์โทรศัพท์ติดต่อสำรองซ้ำ";
    else if (phoneNumberReserve && phoneNumberReserve?.length < 10) {
      errors.PHONE_NUMBER_RESERVE = "กรุณากรอกเบอร์โทรศัพท์ติดต่อสำรองให้ครบ";
    } else if (
      phoneNumberReserve &&
      phoneNumberReserve.length === 10 &&
      !checkPattenPhoneNumber(phoneNumberReserve)
    ) {
      errors.PHONE_NUMBER_RESERVE =
        "กรุณากรอกเบอร์โทรศัพท์ติดต่อสำรองให้ถูกต้อง";
    } else errors.PHONE_NUMBER_RESERVE = "";

    if (typeReceive === "จัดส่งตามที่อยู่" && !name)
      errors.NAME = "กรุณากรอกชื่อ";
    else errors.NAME = "";
    if (typeReceive === "จัดส่งตามที่อยู่" && !lastname)
      errors.LASTNAME = "กรุณากรอกนามสกุล";
    else errors.LASTNAME = "";
    if (typeReceive === "จัดส่งตามที่อยู่" && !address)
      errors.ADDRESS = "กรุณากรอกที่อยู่";
    else errors.ADDRESS = "";
    if (typeReceive === "จัดส่งตามที่อยู่" && !zipCode)
      errors.ZIPCODE = "กรุณากรอกรหัสไปรษณีย์";
    else errors.ZIPCODE = "";
    if (typeReceive === "จัดส่งตามที่อยู่" && !province)
      errors.PROVINCE = "กรุณาเลือกจังหวัด";
    else errors.PROVINCE = "";
    if (typeReceive === "จัดส่งตามที่อยู่" && !district)
      errors.DISTRICT = "กรุณาเลือกเขต/อำเภอ";
    else errors.DISTRICT = "";
    if (typeReceive === "จัดส่งตามที่อยู่" && !subdistrict)
      errors.SUB_DISTRICT = "กรุณาเลือกแขวง/ตำบล";
    else errors.SUB_DISTRICT = "";

    ///เข้ารับที่สาขา
    if (typeReceive === "เข้ารับที่สาขา" && !date)
      errors.DATE = "กรุณาเลือกวันที่";
    else errors.DATE = "";

    const datetime = `${moment(date).format("YYYY-MM-DD")} ${time}`;
    const DateSelect = moment(datetime, "YYYY-MM-DD HH:mm");
    const daydiff = DateSelect.diff(moment(), "hours");
    if (typeReceive === "เข้ารับที่สาขา" && !time)
      errors.TIME = "กรุณาเลือกเวลา";
    else if (typeReceive === "เข้ารับที่สาขา" && time && !isValidTime(time))
      errors.TIME = "กรุณาเลือกเวลาให้ครบ";
    else if (daydiff <= 0)
      errors.TIME = "ไม่สามารถเลือกเวลาก่อนเวลาปัจจุบันได้";
    else errors.TIME = "";

    const allEmpty = Object.keys(errors).every(
      (key) => errors[key as keyof ErrorMessageType] === ""
    );
    if (!allEmpty) {
      setErrorMessage(errors);
      return;
    }

    const data: UpdateData = {
      contractNo: props.contractNo,
      downPaymentNo: props.downPaymentNo,
      channel:
        typeReceive === "จัดส่งตามที่อยู่"
          ? "DELIVER_AT_ADDRESS"
          : "RECEIVE_AT_BRANCH",
      remarkPickUp: note,
    };
    if (typeReceive === "เข้ารับที่สาขา") {
      const dateTime =
        date && time
          ? //แก้ไขโค้ด
            //  `${moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")} ${moment(
            //     time,
            //     "HH:mm"
            //   ).format("HH:mm")}`
            `${date} ${time}`
          : "";
      const ISODateTime = dateTime
        ? moment(dateTime, "YYYY-MM-DD HH:mm").toISOString()
        : "";
      data.pickupDateTime = ISODateTime;
    }

    if (typeReceive === "จัดส่งตามที่อยู่") {
      data.name = `${name} ${lastname}`;
      data.tel = phoneNumber;
      data.reserveTel = phoneNumberReserve;
      data.address = address;
      data.zipcode = zipCode;
      data.province = province;
      data.district = district;
      data.subDistrict = subdistrict;
    }
    // console.log("data", data);
    try {
      const res = await ContractApi.updateCustomerReceiptByCustomer(data);
      if (res.status === 200) {
        setIsOpenModal(true);
      }
    } catch (error: any) {
      swalError(error?.response?.data?.message || "ไม่สามารถบันทึกได้");
    }
  };

  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage} />
      <div className="pt-3 fw-bold">
        <HeaderText label="ช่องทางการรับสินค้า" enableBorder={true} />
      </div>
      <Row className="pt-4">
        <Col xs={6}>
          <ButtonCustom
            className="px-1"
            variant="outlined"
            active={typeReceive === "จัดส่งตามที่อยู่"}
            mode="default"
            btnStyle={{ width: "100%", borderRadius: 12 }}
            textButton="จัดส่งตามที่อยู่"
            onClick={() => {
              setTypeReceive("จัดส่งตามที่อยู่");
              setErrorMessage(initStateErrorMessage);
            }}
          />
        </Col>
        <Col xs={6}>
          <ButtonCustom
            className="px-1"
            variant="outlined"
            active={typeReceive === "เข้ารับที่สาขา"}
            mode="default"
            btnStyle={{ width: "100%", borderRadius: 12 }}
            textButton="เข้ารับที่สาขา"
            onClick={() => {
              setTypeReceive("เข้ารับที่สาขา");
              setErrorMessage(initStateErrorMessage);
            }}
          />
        </Col>
      </Row>
      {typeReceive == "จัดส่งตามที่อยู่" && (
        <Row className="pt-2">
          <Col xs={12} className="pt-2">
            <InputTextField
              placeholder="กรอกเบอร์โทร"
              value={phoneNumber || ""}
              onchange={(event: any) => {
                if (event.target.value.length <= 10) {
                  setPhoneNumber(event.target.value);
                  setErrorMessage({ ...errorMessage, PHONE_NUMBER: "" });
                }
              }}
              heading="เบอร์โทรศัพท์ที่ติดต่อได้สำหรับการส่งสินค้า"
              required
              helperText={errorMessage.PHONE_NUMBER}
              type="number"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
          </Col>
        </Row>
      )}
      {typeReceive == "จัดส่งตามที่อยู่" && (
        <>
          <Row className="pt-2">
            <Col xs={12} className="pt-2">
              <InputTextField
                placeholder="กรอกเบอร์โทร"
                value={phoneNumberReserve || ""}
                onchange={(event: any) => {
                  if (event.target.value.length <= 10) {
                    setPhoneNumberReserve(event.target.value);
                    setErrorMessage({
                      ...errorMessage,
                      PHONE_NUMBER_RESERVE: "",
                    });
                  }
                }}
                heading="เบอร์โทรศัพท์ติดต่อสำรอง"
                helperText={errorMessage.PHONE_NUMBER_RESERVE}
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={6} className="pt-2">
              <InputTextField
                placeholder="กรอกชื่อผู้รับ"
                value={name || ""}
                onchange={(event: any) => {
                  setName(event.target.value);
                  setErrorMessage({ ...errorMessage, NAME: "" });
                }}
                heading="ชื่อผู้รับ"
                helperText={errorMessage.NAME}
                required
              />
            </Col>
            <Col xs={6} className="pt-2">
              <InputTextField
                placeholder="กรอกนามสกุลผู้รับ"
                value={lastname || ""}
                onchange={(event: any) => {
                  setLastname(event.target.value);
                  setErrorMessage({ ...errorMessage, LASTNAME: "" });
                }}
                heading="นามสกุลผู้รับ"
                helperText={errorMessage.LASTNAME}
                required
              />
            </Col>
          </Row>

          <Row className="pt-2">
            <Col xs={12} className="pt-2">
              <InputTextField
                placeholder="กรอกที่อยู่"
                value={address || ""}
                onchange={(event: any) => {
                  setAddress(event.target.value);
                  setErrorMessage({ ...errorMessage, ADDRESS: "" });
                }}
                heading="ที่อยู่"
                required
                helperText={errorMessage.ADDRESS}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={6} lg={6} className="pt-2">
              <InputTextField
                placeholder="กรอกรหัสไปรษณีย์"
                value={zipCode || ""}
                onchange={(event: any) => {
                  if (event.target.value.length <= 5) {
                    setZipCode(event.target.value);
                    // setCheckZipcode(false)
                    setErrorMessage({ ...errorMessage, ZIPCODE: "" });
                  }
                }}
                required
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                helperText={errorMessage.ZIPCODE}
              />
            </Col>
            <Col sm={6} xs={6} lg={6} className="pt-2">
              <AutocompleteSelect
                key={"province"}
                options={optionProvice || []}
                value={
                  _.find(optionProvice, (val: any) => val.value === province) ||
                  null
                }
                placeholder={"เลือกจังหวัด"}
                getOptionLabel={(option: any) => option.label}
                labelId="province"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, PROVINCE: "" });
                    setProvince(value.value);
                  } else if (reason === "clear") setProvince("");
                }}
                required
                helperText={errorMessage.PROVINCE}
              />
            </Col>
            <Col sm={6} xs={6} lg={6} className="pt-2">
              <AutocompleteSelect
                key={"district"}
                options={optionDistrict || []}
                value={
                  _.find(
                    optionDistrict,
                    (val: any) => val.value === district
                  ) || null
                }
                placeholder={"เลือกเขต/อำเภอ"}
                getOptionLabel={(option: any) => option.label}
                labelId="district"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, SUB_DISTRICT: "" });
                    // amplitudeTrack('2_Profile_P_district')
                    setDistrict(value.value);
                  } else if (reason === "clear") setDistrict("");
                }}
                required
                helperText={errorMessage.DISTRICT}
                // helperText={errorMessage.PERSONAL_DISTRICT}
              />
            </Col>
            <Col sm={6} xs={6} lg={6} className="pt-2">
              <AutocompleteSelect
                key={"subdistrict"}
                options={optionSubDistrict || []}
                value={
                  _.find(
                    optionSubDistrict,
                    (val: any) => val.value === subdistrict
                  ) || null
                }
                placeholder={"เลือกแขวง/ตำบล"}
                getOptionLabel={(option: any) => option.label}
                labelId="subdistrict"
                onChange={(event, value, reason) => {
                  if (value) {
                    setErrorMessage({ ...errorMessage, DISTRICT: "" });
                    // amplitudeTrack('2_Profile_P_district')
                    setSubdistrict(value.value);
                  } else if (reason === "clear") setSubdistrict("");
                }}
                required
                helperText={errorMessage.SUB_DISTRICT}
              />
            </Col>
          </Row>
        </>
      )}

      {typeReceive == "เข้ารับที่สาขา" && (
        <Row className="pt-2">
          <Row className="pt-2 pb-2">
            <div className="font-16 font-bold">บริษัท ชิบะโฟน จำกัด</div>
            <div className="pl-2">
              <div className="font-14">
                ที่อยู่ : 168 ซอยสมเด็จพระเจ้าตากสิน 5
              </div>
              <div className="font-14">ถนนสมเด็จพระเจ้าตากสิน</div>
              <div className="font-14">
                เขตคลองสาน แขวงคลองต้นไทร กรุงเทพมหานคร 10600
              </div>
              <div className="font-14">เบอร์โทรศัพท์ : 064 771 2246</div>
              <div className="font-14">เวลาทำการ : ทุกวัน 9:30-18:30 น.</div>
              <div className="font-14">
                แผนที่ :{" "}
                <a
                  href="https://maps.app.goo.gl/8DxmWHbo9fhqmWto6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  คลิกที่นี้
                </a>
              </div>
            </div>
          </Row>
          <Col xs={6}>
            <InputDatePicker
              dateFormat="DD/MM/YYYY"
              required
              key={"Date"}
              label=""
              placeholder="เลือกวันที่"
              value={date}
              onClear={() => setDate("")}
              onChange={(e: any) => {
                setDate(moment(e).format("YYYY-MM-DD"));
                setErrorMessage({ ...errorMessage, DATE: "" });
              }}
              heading={"วันที่"}
              helperText={errorMessage.DATE}
              disablePast={true}
            />
          </Col>
          <Col xs={6}>
            <div>
              <span>เวลา</span>
              <span style={{ color: colors.red }}> *</span>
            </div>

            <TimePicker
              onChange={handleTimeChange}
              initialTime={time}
              helperText={errorMessage.TIME}
            />
          </Col>
        </Row>
      )}

      <Row className="pt-2">
        <Col xs={12} className="pt-4">
          <InputTextField
            placeholder=""
            value={note || ""}
            onchange={(event: any) => {
              setNote(event.target.value);
            }}
            heading="หมายเหตุ"
          />
        </Col>
      </Row>

      {/* <div className="d-flex justify-content-center align-item-center pt-2">
        {typeReceive == "เข้ารับที่สาขา" && <p style={{ color: colors.red }}>*อาจจะใช้เวลา 1 ชั่วโมงสำหรับการดำเนินการ</p>}
        {typeReceive == "จัดส่งตามที่อยู่" && <p style={{ color: colors.red }}>*อาจจะใช้เวลาในการจัดส่งประมาณ 1 - 3 วันแล้วแต่พื้นที่</p>}

    </div> */}

      <ButtonCustom
        className="px-1 my-5"
        // variant="outlined"
        active
        mode="default"
        btnStyle={{ width: "100%", borderRadius: 12 }}
        textButton="บันทึก"
        onClick={() => {
          onSubmit();
        }}
      />

      <ModalCustom
        size="sm"
        title={""}
        modalTheme="default"
        onSubmit={() => {
          setIsOpenModal(false);
          props.onSetStepper && props.onSetStepper();
        }}
        onClose={() => {
          setIsOpenModal(false);
        }}
        textBtnConfirm={"ตกลง"}
        isShowProps={isOpenModal}
        component={
          <>
            {typeReceive === "จัดส่งตามที่อยู่" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                  flexDirection: "column",
                }}
              >
                <img
                  src={imgs.imageSuccess}
                  style={{ width: "50%", height: "50%", marginBottom: "20px" }}
                />
                <span style={{ textAlign: "center" }}>
                  ระบบได้บันทึกข้อมูลการจัดส่งเรียบร้อยแล้วงับ📦✨
                </span>
                <span style={{ textAlign: "center" }}>
                  แอดมินกำลังเตรียมสินค้า และจะจัดส่งให้เร็วที่สุด
                </span>
                <span style={{ textAlign: "center" }}>
                  รออีกหนึ่งอึดใจเท่านั้น! 😊
                </span>
              </div>
            )}

            {typeReceive === "เข้ารับที่สาขา" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                  flexDirection: "column",
                }}
              >
                <img
                  src={imgs.imageSuccess}
                  style={{ width: "50%", height: "50%", marginBottom: "20px" }}
                />
                <span style={{ textAlign: "center" }}>
                  เราได้ทำการนัดวันและเวลา
                </span>
                <span style={{ textAlign: "center" }}>
                  สำหรับรับสินค้าเรียบร้อยแล้วน้า 🎉
                </span>
                <span style={{ textAlign: "center" }}>
                  หากต้องการเปลี่ยนแปลงวันนัดรับสินค้า
                </span>
                <span style={{ textAlign: "center" }}>
                  กรุณาแจ้งแอดมินล่วงหน้า
                </span>
                <span style={{ textAlign: "center" }}>
                  อย่างน้อย 3 วันนะงับ
                </span>
                <span style={{ textAlign: "center" }}>
                  เพื่อให้ทีมงานจัดการให้สะดวกที่สุด
                </span>
                <span style={{ textAlign: "center" }}>ขอบคุณงับ 😊</span>
              </div>
            )}
          </>
        }
      />
    </div>
  );
}
